import React from "react"
import apis from "../utils/NetUtils"
import * as icon from "@ant-design/icons"
import * as ui from  "antd"
import RouterView from "../router/Router"
import * as uiicons from "@icon-park/react"
import GlobalData from "../utils/GlobalData";
import GWAPI from "../utils/GwApi";

const {SubMenu} = ui.Menu
const {Header, Sider,Content} = ui.Layout

export default class Layout extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            staff: {},
            collapsed: true,
            currOpenKey: [],
            userMenus: []
        }
    }
    componentDidMount() {
        this.appInitial()
        this.getUser()
        this.userMenuSync()
        this.countriesSync()
        this.languagesSync()
        this.currenciesSync()
    }
    /**
     * 初始化调用
     */
    appInitial () {
        if (!localStorage.getItem("appId")){
            var that = this
            apis.appClientSync().then(resp=>{
                localStorage.setItem("appId",resp.data.data.client_id)
            })
        }
    }
    /**
     * 加载用户目录
     */
    userMenuSync () {
        var that = this
        apis.getUserMenus({}).then(resp => {
            that.setState({
                userMenus: resp.data.data
            })
        })
    }

    /**
     * 当前登陆用户
     */
    getUser(){
        var that = this
        apis.getSignUserInfor().then(resp => {
            that.setState({
                staff: resp.data.data
            })
            if (window.location.pathname!=="/initial") {
                if (resp.data.data.merchant.group_id === "") {
                    window.location.href  ="/initial"
                }
            }
        })
    }

    /**
     * 国家列表加载
     */
    countriesSync () {
        GWAPI.countries().then(res => {
            GlobalData.countries = res.data
        })
    }

    /**
     * 语言列表加载
     */
    languagesSync () {
        GWAPI.languages().then(res => {
            GlobalData.languages = res.data
        })
    }

    /**
     * 货币列表加载
     */
    currenciesSync () {
        GWAPI.currencies().then(res => {
            GlobalData.currencies = res.data
        })
    }
    render() {
        var that = this
        if (apis.getOpenRoute().indexOf(window.location.pathname) !== -1) {
            return (
                <div style={{width:"100vw",height:"100vh",backgroundColor:"#999999",textAlign:"center"}}>
                    <ui.Spin style={{margin:"25vh auto"}} size={"large"} tip={"登陆验证中..."}/>
                    <RouterView/>
                </div>
            )
        } else {
            return (
                <ui.Layout>
                    <div id={"sidebar"}
                         style={{boxSizing:"border-box",width: GlobalData.collapsed ? "48px" : "208px",position:"fixed",height:"100vh",backgroundColor:"#001529"}}>
                        <div className={"ant-menu ant-menu-root ant-menu-dark"} style={{textAlign: "center", padding:"6px"}} onClick={() => {
                            GlobalData.collapsed = !GlobalData.collapsed
                        }}>
                            <span className={"ant-menu-submenu"} dangerouslySetInnerHTML={{ __html: "<svg width=\"30\" height=\"30\" viewBox=\"0 0 48 48\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M7.94971 11.9497H39.9497\" stroke=\"#fff\" stroke-width=\"4\" stroke-linecap=\"square\" stroke-linejoin=\"miter\"/><path d=\"M7.94971 23.9497H39.9497\" stroke=\"#fff\" stroke-width=\"4\" stroke-linecap=\"square\" stroke-linejoin=\"miter\"/><path d=\"M7.94971 35.9497H39.9497\" stroke=\"#fff\" stroke-width=\"4\" stroke-linecap=\"square\" stroke-linejoin=\"miter\"/></svg>" }}/>
                        </div>
                        <ui.Divider/>
                        <ui.Menu mode={GlobalData.collapsed ? "vertical" : 'inline'}
                                 theme={"dark"}
                                 style={{backgroundColor:"none",width: GlobalData.collapsed ? "48px" : "208px"}}>
                            {that.state.userMenus.map(topMenus => {
                                if (topMenus.children && topMenus.children.length > 0) {
                                    return (
                                        <SubMenu title={topMenus.name} icon={<span dangerouslySetInnerHTML={{ __html: topMenus.icon }}/>} key={topMenus.id}>
                                            {topMenus.children.map(child => {
                                                return (
                                                    <ui.Menu.Item key={child.id}>
                                                        <a href={child.uri}>{child.name}</a>
                                                    </ui.Menu.Item>
                                                )
                                            })}
                                        </SubMenu>
                                    )
                                } else  {
                                    return (
                                        <ui.Menu.Item key={topMenus.id} icon={<span dangerouslySetInnerHTML={{ __html: topMenus.icon }}/>}>
                                            <a href={topMenus.url}>{topMenus.name}</a>
                                        </ui.Menu.Item>
                                    )
                                }
                            })}
                        </ui.Menu>

                    </div>
                    <div style={{marginLeft: GlobalData.collapsed ? "48px" : "208px"}}>
                        <Header style={{backgroundColor:"#ffffff",display:"flex",justifyContent: "space-between",height:"40px",alignItems:"center",padding:"0 20px"}}>
                            <div style={{display:"flex",alignItems:"center",justifyContent:"flex-start"}}>
                                <a href={"/"}>
                                    <strong style={{marginRight:"4px",color:"darkcyan",fontSize:"18px"}}>TUIEX</strong>
                                    <b style={{fontSize:"15px",color:"indigo"}}>推易云商</b>
                                </a>
                            </div>
                            <div>
                                <ui.Badge count={0}>
                                    <icon.BellOutlined/>
                                </ui.Badge>
                                <ui.Button shape={"round"} size={"small"} style={{margin:"0 16px"}} icon={<icon.UserOutlined/>}>您好 ! {that.state.staff.name ? that.state.staff.name : '会员'}</ui.Button>
                                <ui.Button style={{border:"none"}} onClick={() => {
                                    localStorage.removeItem("appToken")
                                    window.location.reload()
                                }} icon={<icon.LogoutOutlined/>}/>
                            </div>
                        </Header>
                        <Content>
                            <RouterView />
                        </Content>
                    </div>
                </ui.Layout>
            )
        }
    }
}