import React from "react"
import apis from "../utils/NetUtils"
import * as icon from "@ant-design/icons"
import * as ui from  "antd"
import LoadUtil from "../utils/LoadUtil"


export default class PrimaryPage extends React.Component{
    render() {
        return (
            <ui.Result status={"warning"} title={"当前业务当前还未产生数据"} style={{marginTop:"15vh"}} extra={
                [<ui.Button type={"primary"} key={"primary"} href={"/"}>返回首页</ui.Button>]
            } />
        )
    }
}