import React from "react"
import apis,{httpClient} from "../../utils/NetUtils"
import * as icon from "@ant-design/icons"
import * as ui from  "antd"
import LoadUtil from "../../utils/LoadUtil";
import moment from "moment";

export default class LabelAccount extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            shipper: {},
            countrys: [],
            tableData: [],
            pagination: {total: 0, currPage: 1},
            keywords: {},
            tableLoading: false,
            editCurr: {},
            editShow: false,
            btnLoading: false
        }
    }
    componentDidMount() {
        this.GetTableData()
    }
    /**
     * 数据列表
     * @param pageNumber
     * @param pageSize
     * @param filter
     * @constructor
     */
    GetTableData=(pageNumber=1,pageSize=24)=>{
        var that = this
        var para = {}
        para.pageNumber = pageNumber
        para.pageSize = pageSize
        var filter = {}
        Object.assign(filter, that.state.keywords)
        that.setState({
            tableLoading: true
        })
        filter.SystemGroup__code = "logictics"
        apis.getPagination("/system/grants",pageNumber,pageSize,filter).then(resp=>{
            that.setState({
                tableLoading: false
            })
            var pageData = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableLoading: false,
                pagination: pageData,
                tableData: resp.data.data.data
            })
        })

    }
    /**
     * 发起搜索
     * @param params
     */
    makeSearch = (params) => {
        this.setState({
            keywords: params
        })
        this.GetTableData()
    }
    render() {
        var that = this
        return (
            <div style={{margin:"26px"}}>
                <ui.Breadcrumb>
                    <ui.Breadcrumb.Item>
                        <a href={"/"}>首页</a>
                    </ui.Breadcrumb.Item>
                    <ui.Breadcrumb.Item>电子面单帐户管理中心</ui.Breadcrumb.Item>
                </ui.Breadcrumb>
                <ui.PageHeader style={{marginTop:"26px"}} ghost={false}
                               title={`电子面单帐户管理中心`}
                               subTitle={"电子面单帐户与余额统一管理配置中心"}/>
                <div id={"toolbar"} style={{marginTop:"16px"}}>
                    <ui.Form onFinish={that.makeSearch} layout={"inline"}>
                        <ui.Form.Item name={"name"}>
                            <ui.Input placeholder={'快递名称...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item>
                            <ui.Button htmlType={"submit"} type={"primary"}>快递搜索</ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </div>
                <ui.Row gutter={16} className={'cart-list'} id={"labelshowcell"}>
                    {that.state.tableData.map(item => {
                        return (
                            <ui.Col key={item.id} span={4} style={{marginTop:"14px"}}>
                                <ui.Card style={{height:"100%",position:"relative"}}
                                    actions={[<ui.Button href={`/carrier/${item.code}`} size={"small"} type={"primary"} shape={"round"}>快递帐户管理</ui.Button>]}
                                >
                                    <ui.Card.Meta
                                        style={{paddingBottom:"70px"}}
                                        description={item.explain? item.explain : '暂无'}
                                        title={item.name}></ui.Card.Meta>
                                </ui.Card>
                            </ui.Col>
                        )
                    })}
                </ui.Row>
                <div style={{display:"block",marginTop:"28px"}}>
                    <ui.Pagination onChange={that.GetTableData} total={that.state.pagination.total} pagesize={24} currency={that.state.pagination.currPage}/>
                </div>
            </div>
        )
    }
}