/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2023/12/11
 */
import React, {Component} from "react";
import * as ui from "antd";
import apis from "../../../utils/NetUtils";
import {BellOutlined} from "@ant-design/icons";
import GlobalData from "../../../utils/GlobalData";


const {TextArea} = ui.Input
const {Option} = ui.Select

export default class Shops extends Component {
    constructor() {
        super();
        this.state = {
            tableData: [],
            paging: {total: 0, pageNumber: 1, pageSize: 20},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            submitting: false,
            grants: []
        }
    }

    componentDidMount() {
        var that = this
        setTimeout(() => {
            that.grantsSync()
            that.tableDataSync(1)
        }, 500)
    }

    /**
     * 加载执行分组
     */
    grantsSync() {
        var param = {}
        param.pageNumber = 1
        param.pageSize = 2000
        param.resource_id = 3
        var that = this
        apis.verifiedAuthorizationsSync(param).then(res => {
            that.setState({
                grants: res.data.data.data
            })
        })
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    makeSearch = (params) => {
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.tableDataSync()
        }, 200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    tableDataSync = (pageNumber = 1) => {
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = 20;
        that.setState({
            tableLoad: true
        })
        Object.assign(para, that.state.searchKey)
        apis.priceTrackingShopList(para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                pageNumber: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                paging: curr
            })
        })
    }


    render() {
        var that = this

        const columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "店铺名称",
                dataIndex: "name"
            },
            {
                title: "店铺说明",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <ui.Button shape={"round"} type={"dashed"}>{row.remark}</ui.Button>
                    )
                }
            },
            {
                title: "可用",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <ui.Button shape={"round"} type={"circle"}>{row.state ? '是' : '否'}</ui.Button>
                    )
                }
            },
            {
                title: "创建时间",
                key: "id",
                render: function (value, row, index) {
                    var dd = new Date(row.created_time)
                    return `${dd.toLocaleDateString()} ${dd.toLocaleTimeString()}`
                }
            },
            {
                title: "管理",
                key: "id",
                width: "72px",
                fixed: "center",
                render: function (value, row, index) {
                    return (
                        <span>
                             <ui.Button onClick={() => {
                                 var data = JSON.parse(JSON.stringify(row))
                                 that.setState({
                                     editCurr: data,
                                     editShow: true
                                 })
                             }} type={"link"}>编辑</ui.Button>
                        </span>
                    )
                }
            }
        ]

        return (
            <div>
                <ui.PageHeader title="订阅店铺管理" subTitle="订阅店铺管理" extra={[
                    <ui.Button key="1" type="primary" shape="round" onClick={() => {
                        that.setState({
                            editCurr: {},
                            editShow: true,
                            submitting: false
                        })
                    }}>新增订阅</ui.Button>
                ]}/>
                <ui.Card>
                    <div style={{marginTop: '22px'}}>
                        <ui.Form onFinish={that.makeSearch} layout={"inline"}>
                            <ui.Form.Item label={"店铺名称"} name={"name__contains"}>
                                <ui.Input placeholder={"店铺名称"}/>
                            </ui.Form.Item>
                            <ui.Form.Item>
                                <ui.Button type={"primary"} htmlType={"submit"}>快速搜索</ui.Button>
                            </ui.Form.Item>
                        </ui.Form>
                    </div>
                </ui.Card>

                <div style={{marginTop: '22px'}}>
                    <ui.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{
                            total: this.state.paging.total,
                            defaultPageSize: 20,
                            defaultCurrent: this.state.paging.pageNumber,
                            onChange: this.tableDataSync
                        }}/>
                </div>

                <ui.Modal visible={that.state.editShow} title={'定时任务执行客户端名称'} onCancel={() => {
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        submitting: false
                    })
                }} footer={null} destroyOnClose>
                    <ui.Form onFinish={(params => {
                        that.setState({
                            submitting: true
                        })
                        if (that.state.editCurr.id) {
                            params.id = that.state.editCurr.id
                        }
                        apis.priceTrackingShopAdmin(params).then(resp => {
                            that.setState({
                                submitting: false
                            })
                            if (resp.data.code === 200) {
                                ui.message.success("提交信息成功")
                                that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                                that.setState({
                                    submitting: false,
                                    editShow: false,
                                    editCurr: {}
                                })
                            } else {
                                ui.message.error(resp.data.data ? resp.data.data : resp.data.msg)
                            }
                        })
                    })} labelCol={{span: 24}} labelAlign={"left"} initialValues={that.state.editCurr}>
                        <ui.Form.Item label={"店铺名称"} name={"name"}
                                      rules={[{required: true, message: '店铺名称'}]}>
                            <ui.Input placeholder={"店铺名称"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"店铺授权"} name={"grant_id"} rules={[{required: true, message: '店铺授权'}]}>
                            <ui.Select placeholder={"店铺授权"} style={{width: "100%"}} disabled={that.state.editCurr.id}>
                                {that.state.grants.map(item => {
                                    return (
                                        <Option value={item.id} key={item.id}>{item.name}</Option>
                                    )
                                })}
                            </ui.Select>
                        </ui.Form.Item>
                        <ui.Form.Item label={"店铺所属市场"} name={"target_country"} rules={[{required: true, message: '店铺所属市场'}]}>
                            <ui.Select placeholder={"店铺所属市场"} style={{width: "100%"}} disabled={that.state.editCurr.target_country} showSearch>
                                {GlobalData.countries.map(item => {
                                    return (
                                        <Option value={item.ccode} key={item.id}>{item.Name} / {item.ShowName}</Option>
                                    )
                                })}
                            </ui.Select>
                        </ui.Form.Item>
                        <ui.Form.Item label={"店铺货币"} name={"primary_currency"} rules={[{required: true, message: '店铺货币'}]}>
                            <ui.Select placeholder={"店铺货币"} style={{width: "100%"}} disabled={that.state.editCurr.primary_currency} showSearch>
                                {GlobalData.currencies.map(item => {
                                    return (
                                        <Option value={item.currency} key={item.id}>{item.showName}[{item.currency}] / {item.name}</Option>
                                    )
                                })}
                            </ui.Select>
                        </ui.Form.Item>
                        <ui.Form.Item label={"店铺语言"} name={"lan_code"} rules={[{required: true, message: '店铺语言'}]}>
                            <ui.Select placeholder={"店铺语言"} style={{width: "100%"}} disabled={that.state.editCurr.lan_code} showSearch>
                                {GlobalData.languages.map(item => {
                                    return (
                                        <Option value={item.Code} key={item.id}>{item.Name} / {item.code}</Option>
                                    )
                                })}
                            </ui.Select>
                        </ui.Form.Item>
                        <ui.Form.Item label={"店铺状态"} name={"state"}
                                      rules={[{required: true, message: '执行客户端名称'}]}>
                            <ui.Radio.Group>
                                <ui.Radio value={true}>可用</ui.Radio>
                                <ui.Radio value={false}>禁用</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"店铺说明"} name={"remark"}
                                      rules={[{required: true, message: '店铺说明'}]}>
                            <TextArea rows={6} placeholder={"店铺说明"}/>
                        </ui.Form.Item>
                        <ui.Form.Item>
                            <ui.Button htmlType={"submit"} loading={that.state.submitting} type={"primary"}
                                       block>立即提交
                            </ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>
            </div>
        )
    }
}