/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2024/3/28
 */
import React, { Component } from 'react'
import * as ui from 'antd'
import { BellOutlined } from '@ant-design/icons'
import LoadUtil from '../../../utils/LoadUtil'
import apis from '../../../utils/NetUtils'


const { TextArea } = ui.Input
const { Option } = ui.Select

export default class LabelDataSimple extends Component {
  constructor() {
    super()
    this.state = {
      simpleData: {},
      formData: {},
      previewLoading: false,
      generateLoading: false,
      previewData: {},
      previewShow: false,
    }
  }


  /**
   * get the label simple data sync
   * @returns {Promise<void>}
   */
  async getLabelSimpleDataSync() {
    LoadUtil.StartLoading()
    var that = this
    apis.getSimpleData('Label').then(res => {
      LoadUtil.HasLoaded()
      that.setState({
        simpleData: res.data.data
      })
    })
  }

  componentDidMount() {
    this.getLabelSimpleDataSync()
  }


  editRef = React.createRef()
  render() {
    var that = this


    return (
      <div style={{ margin: "26px" }}>
        <ui.PageHeader title="面单样例数据" subTitle="面单样例数据，用于自画面单等使用场景" />
        <ui.Row gutter={[16, 16]}>
          <ui.Col span={14}>
            <ui.Card>
              <ui.Form onFinish={param => {
                var args = []
                args.push(param.template)
                args.push(that.state.simpleData)
                that.setState({
                  generateLoading: true
                })
                apis.gwApi("TemplateCompile", args).then(res => {
                  if (res.data.code === 200 && res.data.data.code === 200) {
                    var currentHtml = res.data.data.data.data
                    var tmp = []
                    tmp.push(currentHtml.toString().startsWith("<") ? currentHtml : `<div>${currentHtml}</div>`)
                    tmp.push(param.paper_size)
                    apis.gwApi("ConvertHtmlToPdfFile",tmp).then(ret => {
                      that.setState({
                        generateLoading: false
                      })
                      if (ret.data.code === 200 && ret.data.data.code === 200) {
                        window.open(ret.data.data.data.url, '_blank')
                      } else {
                        ui.message.error(ret.data.data.data.msg ? ret.data.data.data.msg : ret.data.data.msg ? ret.data.data.msg : ret.data.msg)
                      }
                    })
                  } else {
                    that.setState({
                      generateLoading: false
                    })
                    ui.message.error(res.data.data.data.msg ? res.data.data.data.msg : res.data.data.msg ? res.data.data.msg : res.data.msg)
                  }
                })
              }} initialValues={that.state.formData} labelAlign={'left'} labelCol={{ span: 24 }} ref={that.editRef}>
                <ui.Form.Item label="面单模板" name={'template'}
                              rules={[{ required: true, message: '面单模板' }]} initialValue={that.state.template}>
                  <TextArea rows={18} placeholder="请输入面单模板" />
                </ui.Form.Item>
                <ui.Form.Item label="纸张类型" name={'paper_size'} rules={[{ required: true, message: '纸张类型' }]}
                              help={"支持常规纸张或是指定宽度，一般电子面单B2即可"} initialValue={that.state.paper_size}>
                  <ui.Input placeholder="纸张类型" />
                </ui.Form.Item>
                <ui.Form.Item style={{ textAlign: 'right' }}>
                  <ui.Button.Group>
                    <ui.Button type={'primary'}
                               htmlType="submit"
                               loading={that.state.generateLoading}
                               style={{ background: 'rgba(243,134,15,0.6)', border: "none" }}>
                      面单生成
                    </ui.Button>
                    <ui.Button type={'primary'} onClick={() => {
                      var currentValue = that.editRef.current.getFieldsValue()
                      if (currentValue.template) {
                        var args = []
                        args.push(currentValue.template)
                        args.push(that.state.simpleData)
                        that.setState({
                          previewLoading: true
                        })
                        apis.gwApi("TemplateCompile", args).then(res => {
                          that.setState({
                            previewLoading: false
                          })
                          if (res.data.code === 200 && res.data.data.code === 200) {
                            that.setState({
                              previewData: res.data.data.data.data,
                              previewShow: true
                            })
                          } else {
                            ui.message.error(res.data.data.data.msg ? res.data.data.data.msg : res.data.data.msg ? res.data.data.msg : res.data.msg)
                          }
                        })
                      } else {
                        ui.message.warn("你必须优选配置模板内容")
                      }
                    }} loading={that.state.previewLoading}>
                      面单预览
                    </ui.Button>
                  </ui.Button.Group>
                </ui.Form.Item>
              </ui.Form>
            </ui.Card>
          </ui.Col>
          <ui.Col span={10}>
            <ui.Card bodyStyle={{ overflow: 'auto', background: 'f5f5f5' }}>
              {JSON.stringify(that.state.simpleData, null, 4)}
            </ui.Card>
          </ui.Col>
        </ui.Row>
        <ui.Modal bodyStyle={{padding: 'none'}} open={that.state.previewShow} footer={null} onCancel={() => {
          that.setState({
            previewData: {},
            previewShow: false
          })
        }} destroyOnClose>
          <div dangerouslySetInnerHTML={{ __html: that.state.previewData }}></div>
        </ui.Modal>
      </div>
  )
  }
  }