/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2023/12/8
 */

var GlobalData = {
    collapsed: true,
    layout: {
        primarySidebarWidth: '48px',
        headHeight: "40px",
        spreadSidebarWidth: "208px",
        subMenuWith: "168px",
    },
    countries: [],
    languages: [],
    currencies: []
}


export  default GlobalData