import React from "react"
import apis from "../../utils/NetUtils"
import * as ui from  "antd"
import SmartEditor from "../../components/SmartEditor";
import LoadUtil, {HasLoaded, StartLoading} from "../../utils/LoadUtil";
import ImagesCropper from "../../components/tool/ImagesCropper";
import {tools} from "../../utils/ToolUtils";
const  {TextArea} = ui.Input


export default  class GoogleShopping extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            channels: [],
            editCurr: {},
            editShow: false,
            editLoading: false,
            selectedChannel: {},
            keywords: {},
            pagination: {
                total: 0,
                currPage: 1
            },
            variants: [],
            variantViewShow: false,
            variantEditShow: false,
            variantEditCurr: {},
            variantHandleLoading: false,
            imagesVariants: [],
            imagesEditShow: false,
            imagesEditCurr: "",
            ctx: null,
            canvasArea: {
                startX: 0,
                startY: 0,
                endX: 0,
                endY: 0,
                cutBoxWidth: 0,
                cutBoxHeight: 0
            },
            cropper: null,
            canvas: null,
            fileData: "",
            currentImageVariantId: "",
            imagesEditVariantMapping: {},
            isCover: false
        }
    }

    componentDidMount() {
        var that= this
        setTimeout(() => {
            that.tableDataSync(1)
        }, 500)
    }
    componentWillUnmount() {
        this.setState = ()=>false;
    }

    /**
     * 加载产品子变体
     * @param parentId
     */
    syncItemVariants (parentId, primaryKey = "variants") {
        var that = this
        that.setState({
            variants: []
        })
        LoadUtil.StartLoading("变体加载中...")
        apis.getGoogleShoppingItemVariants(parentId).then(res => {
            LoadUtil.HasLoaded()
            var t = {}
            t[primaryKey] = res.data.data
            that.setState(t)
            if (primaryKey === "imagesVariants") {
                var imagesEditVariantMapping = {}
                res.data.data.forEach(elem => {
                    imagesEditVariantMapping[elem.id.toString()] = elem
                })
                that.setState({
                    currentImageVariantId: res.data.data[0].id,
                    imagesEditVariantMapping: imagesEditVariantMapping,
                    imagesEditCurr: "",//res.data.data[0].image_link,
                    fileData: "",
                    imagesEditShow: true
                })
            }
        })
    }

    /**
     * 数据列表
     * @param pageNumber
     * @param pageSize
     * @param filter
     * @constructor
     */
    tableDataSync=(pageNumber=1,pageSize=20)=>{
        var that = this
        var para = {}
        para.pageNumber = pageNumber
        para.pageSize = pageSize
        that.setState({
            tableLoading: true
        })
        Object.assign(para,that.state.keywords)
        apis.googleShoppingProducts(para).then(resp=>{
            that.setState({
                tableLoading: false
            })
            var pageData = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableLoading: false,
                pagination: pageData,
                tableData: resp.data.data.data
            })
        })

    }

    editRef = React.createRef()
    render() {
        var that = this
        var columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "产品MPN",
                dataIndex: "item_group"
            },
            {
                title: "可推送",
                key: "id",
                render: function (value, row, index) {
                    return <ui.Button type={"primary"} size={"small"} style={{color: "#fff",backgroundColor: row.sync ? "#2b5b5d" : "#cb5966"}} shape={"circle"}>{row.sync ? '是' : '否'}</ui.Button>
                }
            },
            {
                key: "id",
                width:"70px",
                align:"center",
                render: function (value, row, index) {
                    return <ui.Image src={row.image_link.replace("https:","").replace("http:","")} style={{width:"68px",height:"68px",objectFit:"cover"}}/>
                }
            },
            {
                title: '产品标题',
                key: "id",
                width: "40%",
                render: function (value, row, index) {
                    var heightlight = row.highlights.split("\n")
                    return (
                        <div>
                            <small>
                                <h4>
                                    <span style={{color:"#ffffff",backgroundColor: "#003e50", padding:"3px"}}>
                                        {row.item_category}
                                    </span>
                                </h4>
                            </small>
                            <a href={row.link} target={"_blank"}>
                                <h4 style={{color:"#666666",fontSize:"18px",fontWeight: "700"}}>
                                    <span style={{backgroundColor: "#e5ebdb",color:"rgb(206 7 253)", padding:"3px", marginRight:"6px", fontSize:"12px",borderRadius:"1px"}}>{row.brand}</span>
                                    {row.title}
                                </h4>
                            </a>
                            <p style={{marginLeft:"8px",color:"#666"}}>{row.mpn}</p>
                            {heightlight.length > 0 &&
                                <div>
                                    <ui.Divider style={{margin: "6px 0",fontSize: "12px", color:"#ccc"}} orientation={"left"}>产品亮点</ui.Divider>
                                    <ui.List
                                        size="small"
                                        dataSource={heightlight}
                                        renderItem={(item) => <ui.List.Item style={{padding:"6px 0"}}>{item}</ui.List.Item>}
                                    />
                                </div>
                            }
                        </div>
                    )
                }
            },
            {
                title: '推送渠道',
                key: "id",
                render: function (value, row, index) {
                    return (
                        <div>
                            <h4 style={{color:"#897a74"}}>{row.transform_channel.name}</h4>
                            <small>{row.transform_channel.remark}</small>
                        </div>
                    )
                }
            },
            {
                title: '推送任务指纹',
                key: "id",
                render: function (value, row, index) {
                   return row.current_sync_figure ? row.current_sync_figure : '尚未推送'
                }
            },
            {
                title: '创建时间',
                key: "id",
                render: function (value, row, index) {
                    var dd = new Date(row.create_time)
                    return <span>{dd.toLocaleDateString()}  {dd.toLocaleTimeString()}</span>
                }
            },
            {
                title: '最后更新',
                key: "id",
                render: function (value, row, index) {
                    var dd = new Date(row.last_modified)
                    return <span>{dd.toLocaleDateString()}  {dd.toLocaleTimeString()}</span>
                }
            },
            {
                title: '管理',
                key: "id",
                fixed: "right",
                width: 140,
                render: function (value, row, index) {
                    return (
                        <span>
                            <ui.Button type={"link"} size={"small"} onClick={() => {
                                var data = JSON.parse(JSON.stringify(row))
                                data.sale_chanel = data.sale_chanel.split(",")
                                that.setState({
                                    editCurr: data,
                                    editShow: true,
                                    editLoading: false
                                })
                            }}>产品编辑</ui.Button>
                            <ui.Button type={"link"} size={"small"} onClick={() => {
                                console.log("变体编辑")
                                that.syncItemVariants(row.id)
                                that.setState({
                                    variantViewShow: true
                                })
                            }}>变体编辑</ui.Button>
                             <ui.Button type={"link"} size={"small"} onClick={() => {
                                 that.syncItemVariants(row.id, "imagesVariants")
                             }}>图片裁剪</ui.Button>
                        </span>
                    )
                }
            }
        ]
        var cols = [
            {
                title: "skuId",
                dataIndex: "id"
            },
            {
                title: "sku",
                dataIndex: "offer_id"
            },
            {
                title: "价格",
                dataIndex: "price"
            },
            {
                title: "销售价格",
                dataIndex: "sale_price"
            },
            {
                title: "货币",
                key: "id",
                render: function (value, row, index) {
                    return row.currency.toUpperCase()
                }
            },
            {
                title: "产品重量",
                key: "id",
                render: function (value, row, index) {
                    return row.product_weight
                }
            },
            {
                title: "产品长",
                key: "id",
                render: function (value, row, index) {
                    return row.product_length
                }
            },
            {
                title: "产品宽",
                key: "id",
                render: function (value, row, index) {
                    return row.product_width
                }
            },
            {
                title: "产品高",
                key: "id",
                render: function (value, row, index) {
                    return row.product_height
                }
            },
            {
                title: "变体管理",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <span>
                            <ui.Button type={"link"} size={"small"} onClick={() => {
                                var data = JSON.parse(JSON.stringify(row))
                                that.setState({
                                    variantEditCurr: data,
                                    variantEditShow: true,
                                    variantHandleLoading: false
                                })
                            }}>编辑</ui.Button>
                        </span>
                    )
                }
            }
        ]
        return (
            <div style={{margin: "26px"}}>
                <ui.Breadcrumb>
                    <ui.Breadcrumb.Item>
                        <a href={"/"}>首页</a>
                    </ui.Breadcrumb.Item>
                    <ui.Breadcrumb.Item>谷歌购物产品管理系统</ui.Breadcrumb.Item>
                </ui.Breadcrumb>
                <ui.PageHeader style={{marginTop: "26px"}} ghost={false}
                               title={`谷歌购物`} subTitle={"对谷歌购物商品进行优化再上线"} extra={[]}/>
                <div id={"toolbar"} style={{margin: "16px"}}>
                    <ui.Form onFinish={param => {
                        that.setState({
                            keywords: param
                        })
                        that.tableDataSync(1)
                    }} layout={"inline"}>
                        <ui.Form.Item name={"tilte__icontains"} label="产品名称">
                            <ui.Input placeholder={'产品名称...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item name={"offer_if"} label="产品SKU">
                            <ui.Input placeholder={'产品SKU...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item name={"item_group"} label="产品ID">
                            <ui.Input placeholder={'产品ID...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item>
                            <ui.Button htmlType={"submit"} type={"primary"}>产品搜索</ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </div>
                <div style={{marginTop: "26px"}}>
                    <ui.Card>
                        <ui.Table
                            dataSource={that.state.tableData}
                            columns={columns}
                            loading={that.state.tableLoading}
                            pagination={{
                                total: that.state.pagination.total,
                                defaultPageSize: 20,
                                defaultCurrent: that.state.pagination.currPage,
                                onChange: that.tableDataSync
                            }}
                            rowKey={"id"}/>
                    </ui.Card>
                </div>

                <ui.Modal mask open={that.state.variantEditShow} footer={null} onCancel={() => {
                    that.setState({
                        variantEditCurr: {},
                        variantEditShow: false,
                        variantHandleLoading: false
                    })
                }} destroyOnClose>
                    <h2>变体编辑</h2>
                    <ui.Divider/>
                    <ui.Form onFinish={params => {
                        params.id = that.state.variantEditCurr.id
                        that.setState({
                            variantHandleLoading: true
                        })
                        apis.updateGoogleShoppingVariant(params).then(res => {
                            that.setState({
                                variantHandleLoading: false
                            })
                            if (res.data.code === 200) {
                                ui.message.success("更新谷歌购物产品信息成功")
                                that.syncItemVariants(that.state.variantEditCurr.id)
                                that.setState({
                                    variantEditCurr: {},
                                    variantEditShow: false,
                                    variantHandleLoading: false
                                })
                            } else {
                                ui.message.error(res.data.data.msg ? res.data.data.msg : res.data.msg)
                            }
                        })
                    }} initialValues={that.state.variantEditCurr} labelAlign={"left"} labelCol={{span: 24}}>
                        <ui.Form.Item label={"产品可推送"} name={"sync"}
                                      rules={[{required: true, message: "产品可推送"}]}>
                            <ui.Radio.Group>
                                <ui.Radio value={true}>是</ui.Radio>
                                <ui.Radio value={false}>否</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品可售"} name={"state"} rules={[{required: true, message: "产品可售"}]}>
                            <ui.Radio.Group>
                                <ui.Radio value={true}>是</ui.Radio>
                                <ui.Radio value={false}>否</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品价格"} name={"price"} rules={[{required: true, message: "产品价格"}]}>
                            <ui.InputNumber style={{width: "100%"}} min={0.01} placeholder={"产品价格"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"长度单位"} name={"unit_of_length"}
                                      rules={[{required: true, message: "长度单位"}]}>
                            <ui.Input placeholder={"长度单位"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品全球编码"} name={"gtin"}
                                      rules={[{required: false, message: "产品全球编码"}]} help={"支持UPC,EIN等"}>
                            <ui.Input placeholder={"产品全球编码"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"重量单位"} name={"unit_of_weight"}
                                      rules={[{required: true, message: "重量单位"}]}>
                            <ui.Input placeholder={"重量单位"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"销售价格"} name={"sale_price"}
                                      rules={[{required: true, message: "销售价格"}]}>
                            <ui.InputNumber style={{width: "100%"}} min={0.01} precision={2} placeholder={"销售价格"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品重量"} name={"product_weight"}
                                      rules={[{required: true, message: "产品重量"}]}>
                            <ui.InputNumber style={{width: "100%"}} min={1} precision={0} placeholder={"产品重量"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"配送重量"} name={"shipping_weight"}
                                      rules={[{required: true, message: "配送重量"}]}>
                            <ui.InputNumber style={{width: "100%"}} min={1} precision={0} placeholder={"配送重量"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品长度"} name={"product_length"}
                                      rules={[{required: true, message: "产品长度"}]}>
                            <ui.InputNumber style={{width: "100%"}} min={1} parser={0} placeholder={"产品长度"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品宽度"} name={"product_width"}
                                      rules={[{required: true, message: "产品宽度"}]}>
                            <ui.InputNumber style={{width: "100%"}} min={1} precision={0} placeholder={"产品宽度"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品高度"} name={"product_height"}
                                      rules={[{required: true, message: "产品高度"}]}>
                            <ui.InputNumber style={{width: "100%"}} min={1} precision={0} placeholder={"产品高度"}/>
                        </ui.Form.Item>
                        <ui.Form.Item style={{textAlign: 'right'}}>
                            <ui.Button type="primary" shape="round" loading={that.state.variantHandleLoading}
                                       htmlType="submit">提交产品信息
                            </ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>

                <ui.Modal mask width={"90vw"} open={that.state.variantViewShow} footer={null} onCancel={() => {
                    that.setState({
                        variantViewShow: false
                    })
                }} destroyOnClose>
                    <h2>产品变体信息</h2>
                    <ui.Divider/>
                    <div style={{minHeight: "60vh"}}>
                        <ui.Table
                            dataSource={that.state.variants}
                            columns={cols}
                            pagination={false}
                            rowKey={"id"}/>
                    </div>
                </ui.Modal>

                <ui.Modal width={"760px"} open={that.state.editShow} footer={null} onCancel={() => {
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        editLoading: false
                    })
                }} destroyOnClose>
                    <h2>谷歌购物产品编辑</h2>
                    <small>请按照谷歌购物官方说明填写相应参数,谷歌官方填写说明：https://support.google.com/merchants/answer/7052112?visit_id=638342294308329991-3116790194&rd=1</small>
                    <ui.Divider/>
                    <ui.Form ref={that.editRef} onFinish={params => {
                        params.id = that.state.editCurr.id
                        that.setState({
                            editLoading: true
                        })
                        if (params.sale_chanel) {
                            params.sale_chanel = params.sale_chanel.join(",")
                        }
                        apis.makeGoogleShoppingItemUpdate(params).then(res => {
                            that.setState({
                                editLoading: false
                            })
                            if (res.data.code === 200) {
                                ui.message.success("更新谷歌购物产品信息成功")
                                that.setState({
                                    editCurr: {},
                                    editShow: false,
                                    editLoading: false
                                })
                                that.tableDataSync(that.state.editCurr.id ? that.state.pagination.currPage : 1)
                            } else {
                                ui.message.error(res.data.data.msg ? res.data.data.msg : res.data.msg)
                            }
                        })
                    }} initialValues={that.state.editCurr} labelAlign={"left"} labelCol={{span: 24}}>
                        <ui.Form.Item label={"产品标题"} name={"title"} rules={[{required: true, message: "产品标题"}]}>
                            <ui.Input placeholder={"产品标题"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"品牌名称"} name={"brand"} rules={[{required: true, message: "品牌名称"}]}>
                            <ui.Input placeholder={"品牌名称"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品亮点"} name={"highlights"}
                                      rules={[{required: false, message: "产品亮点"}]} help={"建议五点亮点,换行一个"}>
                            <TextArea rows={10} placeholder={"产品亮点"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品可推送"} name={"sync"}
                                      rules={[{required: true, message: "产品可推送"}]}>
                            <ui.Radio.Group>
                                <ui.Radio value={true}>是</ui.Radio>
                                <ui.Radio value={false}>否</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品可售"} name={"state"} rules={[{required: true, message: "产品可售"}]}>
                            <ui.Radio.Group>
                                <ui.Radio value={true}>是</ui.Radio>
                                <ui.Radio value={false}>否</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品编码"} name={"gtin"} rules={[{required: false, message: "产品编码"}]}
                                      help={"支持upc,ean等，图书请使用图片全球编码"}>
                            <ui.Input placeholder={"产品编码"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"长度单位"} name={"unit_of_length"}
                                      rules={[{required: true, message: "长度单位"}]}>
                            <ui.Input placeholder={"长度单位"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"重量单位"} name={"unit_of_weight"}
                                      rules={[{required: true, message: "重量单位"}]}>
                            <ui.Input placeholder={"重量单位"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"谷歌购物产品分类"} name={"google_product_category"}
                                      help={"详细请参考：https://support.google.com/merchants/answer/7052112?visit_id=638342294308329991-3116790194&rd=1#product_category，此处填入分类ID"}
                                      rules={[{required: true, message: "谷歌购物产品分类"}]}>
                            <ui.Input placeholder={"谷歌购物产品分类"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"销售渠道"} name={"sale_chanel"}
                                      rules={[{required: true, message: "销售渠道"}]}>
                            <ui.Checkbox.Group>
                                <ui.Checkbox value={"online"}>online</ui.Checkbox>
                                <ui.Checkbox value={"offline"}>offline</ui.Checkbox>
                                <ui.Checkbox value={"action"}>action</ui.Checkbox>
                            </ui.Checkbox.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品情况"} name={"condition"}
                                      rules={[{required: true, message: "产品类型"}]}>
                            <ui.Radio.Group>
                                <ui.Radio value={"new"}>New</ui.Radio>
                                <ui.Radio value={"refurbished"}>Refurbished</ui.Radio>
                                <ui.Radio value={"used"}>Used</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"成人用品"} name={"adult"}
                                      rules={[{required: false, message: "成人用品"}]}>
                            <ui.Radio.Group>
                                <ui.Radio value={true}>是</ui.Radio>
                                <ui.Radio value={false}>否</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品材质"} name={"material"}
                                      rules={[{required: false, message: "产品材质"}]}>
                            <ui.Input placeholder={"产品材质"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品图案"} name={"pattern"}
                                      rules={[{required: false, message: "产品图案"}]}>
                            <ui.Input placeholder={"产品图案"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品颜色"} name={"color"}
                                      rules={[{required: false, message: "产品颜色"}]}>
                            <ui.Input placeholder={"产品颜色"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品规格"} name={"size"} rules={[{required: false, message: "产品规格"}]}>
                            <ui.Input placeholder={"产品规格"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"尺码类型"} name={"size_type"}
                                      rules={[{required: false, message: "尺码类型"}]} help={"时尚器必填"}>
                            <ui.Radio.Group>
                                <ui.Radio value={"regular"}>Regular</ui.Radio>
                                <ui.Radio value={"petite"}>Petite</ui.Radio>
                                <ui.Radio value={"maternity"}>Maternity</ui.Radio>
                                <ui.Radio value={"big"}>Big</ui.Radio>
                                <ui.Radio value={"tall"}>Tall</ui.Radio>
                                <ui.Radio value={"Plus"}>Petite</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"尺码标准"} name={"size_system"}
                                      rules={[{required: false, message: "尺码标准"}]} help={"尺码标准"}>
                            <ui.Radio.Group>
                                {["US", "UK", "EU", "DE", "FR", "FR", "CN", "IT", "BR", "MEX", "AU"].map((item, index) => {
                                    return <ui.Radio value={item} key={index}>{item}</ui.Radio>
                                })}
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"年龄组"} name={"age_group"}
                                      rules={[{required: false, message: "尺码标准"}]}
                                      help={"For all apparel products that are targeted to people in Brazil, France, Germany, Japan, the UK, and the US as well as all products with assigned age groups;for free listings for all Apparel & Accessories (ID: 166) products"}>
                            <ui.Radio.Group>
                                <ui.Radio.Group>
                                    <ui.Radio value={"newborn"}>0-3 months old</ui.Radio>
                                    <ui.Radio value={"infant"}>3-12 months old</ui.Radio>
                                    <ui.Radio value={"toddler"}>1-5 years old</ui.Radio>
                                    <ui.Radio value={"kids"}>5-13 years old</ui.Radio>
                                    <ui.Radio value={"adult"}>Teens or older</ui.Radio>
                                </ui.Radio.Group>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"适用性别"} name={"gender"}
                                      rules={[{required: false, message: "适用性别"}]}>
                            <ui.Radio.Group>
                                <ui.Radio.Group>
                                    <ui.Radio value={"male"}>男性</ui.Radio>
                                    <ui.Radio value={"female"}>女性</ui.Radio>
                                    <ui.Radio value={"unisex"}>中性</ui.Radio>
                                </ui.Radio.Group>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品重量"} name={"product_weight"}
                                      rules={[{required: false, message: "产品重量"}]}>
                            <ui.InputNumber precision={2} style={{width: "100%"}} placeholder={"产品重量"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"配送重量"} name={"shipping_weight"}
                                      rules={[{required: true, message: "配送重量"}]}>
                            <ui.InputNumber style={{width: "100%"}} min={1} precision={0} placeholder={"配送重量"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品长度"} name={"product_length"}
                                      rules={[{required: false, message: "产品长度"}]}>
                            <ui.InputNumber precision={2} style={{width: "100%"}} placeholder={"产品长度"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品宽度"} name={"product_width"}
                                      rules={[{required: false, message: "产品宽度"}]}>
                            <ui.InputNumber precision={2} style={{width: "100%"}} placeholder={"产品宽度"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品高度"} style={{width: "100%"}} name={"product_height"}
                                      rules={[{required: false, message: "产品高度"}]}>
                            <ui.InputNumber precision={2} style={{width: "100%"}} placeholder={"产品高度"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品描述"} name={"description"} onChange={html => {
                            that.editRef.current.setFieldsValue({
                                description: html
                            })
                        }} rules={[{required: true, message: "产品描述"}]}>
                            {/*<ui.Input.TextArea rows={6} placeholder={"产品描述"}/>*/}
                            <SmartEditor/>
                        </ui.Form.Item>
                        <ui.Form.Item style={{textAlign: 'right'}}>
                            <ui.Button type="primary" shape="round" loading={that.state.editLoading}
                                       htmlType="submit">提交产品信息
                            </ui.Button>
                        </ui.Form.Item>

                    </ui.Form>
                </ui.Modal>
                <ui.Modal title={"产品图片管理"} width={"860px"} mask open={that.state.imagesEditShow}
                          footer={<div style={{width:"100%", textAlign: "center"}}><ui.Button type={"primary"} onClick={() => {
                                  if (that.state.fileData!=="") {
                                      LoadUtil.StartLoading()
                                      apis.fileUpload({file: that.state.fileData}).then(res => {
                                          LoadUtil.HasLoaded()
                                          if (res.data.code === 200 && res.data.data) {
                                              LoadUtil.StartLoading("开始更新产品信息...")
                                              var current = that.state.imagesEditVariantMapping[that.state.currentImageVariantId.toString()]
                                              var item = {}
                                              item.id = that.state.currentImageVariantId
                                              if (that.state.isCover) {
                                                  item.image_link = res.data.data.retUrl
                                                  current.image_link = res.data.data.retUrl
                                              } else {
                                                  item.additional_image_links = current.additional_image_links.replace(that.state.imagesEditCurr, res.data.data.retUrl)
                                                  current.additional_image_links = item.additional_image_links
                                              }
                                              apis.updateGoogleShoppingVariant(item).then(resp => {
                                                  LoadUtil.HasLoaded()
                                                  if (resp.data.code ===200) {
                                                      ui.message.success("更新产品图片成功")
                                                      var exChangeData = that.state.imagesEditVariantMapping
                                                      exChangeData[that.state.currentImageVariantId.toString()] = current
                                                      that.setState({
                                                          imagesEditCurr: res.data.data.retUrl,
                                                          imagesEditVariantMapping: exChangeData
                                                      })
                                                  } else {
                                                      ui.message.error(resp.data.data.msg ? resp.data.data.msg : resp.data.msg)
                                                  }
                                              })
                                          } else {
                                              ui.message.error("上传裁剪图片失败，请点击重试")
                                          }
                                      })
                                  } else {
                                      ui.message.error("请先对图片进行裁剪!")
                                  }
                              }}>立即裁剪</ui.Button></div>}
                          onCancel={() => {
                              that.setState({
                                  imagesEditShow: false,
                                  imagesVariants: [],
                                  imagesEditCurr: ""
                              })
                          }} destroyOnClose>
                    {that.state.imagesEditCurr === "" ?
                    <div style={{"minHeight": "220px",textAlign:"center",color:"red"}}>请选择需要裁剪的图片!</div> :
                    <ImagesCropper src={that.state.imagesEditCurr} onChange={file => {
                        that.setState({
                            fileData: file
                        })
                    }}/>
                    }
                    <ui.Tabs items={that.state.imagesVariants.map(ele => {
                        var images = []
                        images = images.concat([ele.image_link])
                        if (ele.additional_image_links !=="") {
                            images = images.concat(ele.additional_image_links.split("||"))
                        }
                        return {
                            label: ele.offer_id,
                            key: ele.id,
                            children: <div>
                                <ui.Row gutter={"16px"}>
                                    {images.map((img, index) => {
                                        img = img.replace("https:","").replace("http:","")
                                        return <ui.Col span={4} key={index}>
                                            <ui.Card style={{background: that.state.imagesEditCurr === img ? "green" : "#f2f2f2"}} bodyStyle={{padding:"6px"}}>
                                                <ui.Image src={img} preview={false} onClick={() => {
                                                    that.setState({
                                                        imagesEditCurr: ""
                                                    })
                                                    LoadUtil.StartLoading("图片加载中...")
                                                    tools.imgUrlToBase64(img).then(res => {
                                                        LoadUtil.HasLoaded()
                                                        that.setState({
                                                            imagesEditCurr: res,
                                                            currentImageVariantId: ele.id,
                                                            fileData: "",
                                                            isCover: index === 0
                                                        })
                                                    }).catch(e => {
                                                        ui.message.error(e.toString())
                                                    })
                                                }}></ui.Image>
                                            </ui.Card>
                                        </ui.Col>
                                    })}
                                </ui.Row>
                            </div>,
                        }
                    })}/>

                </ui.Modal>
            </div>
        );
    }
}