/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2023/12/8
 */
import React, {Component} from "react";
import * as ui from "antd";
import apis from "../../../utils/NetUtils";
import {BellOutlined} from "@ant-design/icons";
import {Loading} from "@icon-park/react";
import LoadUtil from "../../../utils/LoadUtil";


const {TextArea} = ui.Input
const {Option} = ui.Select

export default class Subscribe extends Component {
    constructor() {
        super();
        this.state = {
            tableData: [],
            paging: {total: 0, pageNumber: 1, pageSize: 20},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            submitting: false,
            subscribe: {}
        }
    }

    componentDidMount() {
        var that = this
        setTimeout(() => {
            that.engineSubscribeSync()
            that.tableDataSync(1)
        }, 200)
    }


    /**
     * 加载执行分组
     */
    engineSubscribeSync() {
        var param = {}
        param.pageNumber = 1
        param.pageSize = 2000
        var that = this
        apis.getUserCrawlerEngineSubscribeInfo(param).then(res => {
            that.setState({
                subscribe: res.data.data
            })
        })
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    makeSearch = (params) => {
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.tableDataSync()
        }, 1000)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    tableDataSync = (pageNumber = 1) => {
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = 20;
        that.setState({
            tableLoad: true
        })
        Object.assign(para, that.state.searchKey)
        apis.crawlerEngine(para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                pageNumber: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                paging: curr
            })
        })
    }


    render() {
        var that = this

        const columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "采集引擎",
                key: "id",
                render: function (value, row, index) {
                    return row.name
                }
            },
            {
                title: "网站地址",
                key: "id",
                render: function (value, row, index) {
                    return row.gate_url
                }
            },
            {
                title: "数据引擎",
                key: "id",
                render: function (value, row, index) {
                    return row.gather_type.name
                }
            },
            {
                title: "采集器",
                key: "id",
                render: function (value, row, index) {
                    return row.spider
                }
            },
            {
                title: "可用",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <ui.Button shape={"round"} type={"circle"}>{row.state ? '是' : '否'}</ui.Button>
                    )
                }
            },
            {
                title: "管理",
                key: "id",
                fixed: "center",
                width: "80px",
                render: function (value, row, index) {
                    var hasSubscribed  = that.state.subscribe.origin_resource_id ? that.state.subscribe.origin_resource_id.split(',') : []
                    console.log(hasSubscribed)
                    return (
                        <span>
                             <ui.Button onClick={() => {
                                 LoadUtil.StartLoading()
                                 apis.handUserSpiderEngineSubscribe(row.id.toString()).then(res => {
                                     LoadUtil.HasLoaded()
                                     if (res.data.code === 200) {
                                         ui.message.success("修改采集源订阅状态成功")
                                         that.engineSubscribeSync()
                                         that.tableDataSync(that.state.paging.pageNumber)
                                     } else {
                                         ui.message.error(res.data.msg)
                                     }
                                 })
                             }} type={"link"} style={{color: hasSubscribed.includes(row.id.toString()) ? "green" : "red"}}>{hasSubscribed.includes(row.id.toString()) ? "取消订阅" : "立即订阅"}</ui.Button>
                        </span>
                    )
                }
            }
        ]

        return (
            <div>
                <ui.PageHeader title="价格跟踪频道订阅管理" subTitle="订阅参与跟踪的采集频道" extra={[
                ]}/>
                <ui.Card>
                    <div style={{marginTop: '22px'}}>
                        <ui.Form onFinish={that.makeSearch} layout={"inline"}>
                            <ui.Form.Item label={"订阅名称"} name={"name__contains"}>
                                <ui.Input placeholder={"订阅名称"}/>
                            </ui.Form.Item>
                            <ui.Form.Item>
                                <ui.Button type={"primary"} htmlType={"submit"}>快速搜索</ui.Button>
                            </ui.Form.Item>
                        </ui.Form>
                    </div>
                </ui.Card>

                <div style={{marginTop: '22px'}}>
                    <ui.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{
                            total: this.state.paging.total,
                            defaultPageSize: 20,
                            defaultCurrent: this.state.paging.pageNumber,
                            onChange: this.tableDataSync
                        }}/>
                </div>
            </div>
        )
    }
}