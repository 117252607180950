/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2023/12/8
 */
import React, {Component, Fragment} from "react";
import * as ui from "antd";
import {Route, Switch} from "react-router-dom";
import GlobalData from "../../../utils/GlobalData";
import Product from "./Product";
import Subscribe from "./Subscribe";
import TrackingObjects from "./TrackingObjects";
import Shops from "./Shops";


export default class PriceLayer extends React.Component{
    constructor(prop) {
        super(prop);
    }
    render() {
        const that = this
        return (
            <Fragment>
                <div>
                    <div style={{backgroundColor:"#ffffff",left: GlobalData.collapsed ? GlobalData.layout.primarySidebarWidth : GlobalData.layout.spreadSidebarWidth,top: GlobalData.layout.headHeight,padding:"26px",height: `calc(100vh - ${GlobalData.layout.headHeight})`,position:"fixed",width: GlobalData.layout.subMenuWith}}>
                        <h2 style={{fontWeight:'bold',margin:"0 auto"}}>价格跟踪</h2>
                        <ui.Divider/>
                        <ui.Menu mode={"inline"} style={{borderRight:"none"}}>
                            <ui.Menu.Item key={"t1"}>
                                <a href={"/bigdata/price_tracking/subscribe"}>频道订阅</a>
                            </ui.Menu.Item>
                            <ui.Menu.Item key={"t4"}>
                                <a href={"/bigdata/price_tracking/shop"}>店铺管理</a>
                            </ui.Menu.Item>
                            <ui.Menu.Item key={"t2"}>
                                <a href={"/bigdata/price_tracking/tracking_object"}>跟踪对像</a>
                            </ui.Menu.Item>
                            <ui.Menu.Item key={"t3"}>
                                <a href={"/bigdata/price_tracking/item"}>产品信息</a>
                            </ui.Menu.Item>
                        </ui.Menu>
                    </div>
                </div>
                <div style={{position: "fixed", top: GlobalData.layout.headHeight,height: `calc(100vh - ${GlobalData.layout.headHeight})`, overflowY:"auto",right:0,width: GlobalData.collapsed ? `calc(100vw - ${GlobalData.layout.primarySidebarWidth} - ${GlobalData.layout.subMenuWith})` : `calc(100vw - ${GlobalData.layout.spreadSidebarWidth} - ${GlobalData.layout.subMenuWith})`,padding:"14px"}}>
                    <Switch>
                        <Route exact path={`/bigdata/price_tracking/subscribe`} component={Subscribe}></Route>
                        <Route exact path={`/bigdata/price_tracking/tracking_object`} component={TrackingObjects}></Route>
                        <Route exact path={`/bigdata/price_tracking/item`} component={Product}></Route>
                        <Route exact path={`/bigdata/price_tracking/shop`} component={Shops}></Route>
                    </Switch>
                </div>
            </Fragment>
        )
    }
}