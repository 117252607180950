

var getObjectType = (obj) => {
    return Object.prototype.toString.call(obj).slice(8,-1)
}


/**
 * 对像中包含子字段处理方法
 * @type {{encode2Objecj: (function(*, *): {})}}
 */
var convertObjectSubField = {
    /**
     * 将字段自动转换成对像
     * @param obj
     * @param fields
     * @returns {{}}
     */
    encodeObject2Fields: function (obj){
        var newObj = {}
        Object.keys(obj).forEach(nk => {
            if (getObjectType(obj[nk]).toLowerCase() === "object") {
                Object.keys(obj[nk]).forEach(subKey =>{
                    newObj[`${nk}___${subKey}`] = obj[nk][subKey]
                })
            } else  {
                newObj[nk] = obj[nk]
            }
        })
        return newObj
    },
    /**
     * 将字段变成json对像
     * @param obj
     * @param fields
     */
    decodeFields2Object: function (obj) {
        var newObj = {}
        Object.keys(obj).forEach(key => {
            if (key.toString().indexOf("___")!==-1) {
                var keyAndSubKey = key.split("___")
                newObj[keyAndSubKey[0]] = newObj[keyAndSubKey[0]] ? newObj[keyAndSubKey[0]] : {}
                newObj[keyAndSubKey[0]][keyAndSubKey[1]] = newObj[keyAndSubKey[0]][keyAndSubKey[1]] ? newObj[keyAndSubKey[0]][keyAndSubKey[1]] : {}
                newObj[keyAndSubKey[0]][keyAndSubKey[1]] = obj[key]
            } else {
                newObj[key] = obj[key]
            }
        })
        return newObj
    }
}

var t = function (txt, data = null) {
    var currentLanguage  = localStorage.getItem("locale") ? localStorage.getItem("locale") : "en"
    var targetText = txt
    return targetText
}

var tools = {
    /**
     * 图片转base64
     * @param imgUrl
     * @returns {Promise<unknown>}
     */
    imgUrlToBase64: function (imgUrl) {
        imgUrl = imgUrl.replace("catalog//","catalog/")
        return new Promise((resolve, reject) =>{
            var canvas = document.createElement("canvas")
            var ctx = canvas.getContext("2d")
            var img= new Image()
            // img.crossOrigin = "Anonymous"
            img.setAttribute("crossOrigin","anonymous")
            img.src = `${imgUrl}?${Date.now()}`
            img.onload = function () {
                canvas.height = img.height
                canvas.width = img.width
                ctx.drawImage(img,0,0)
                resolve(canvas.toDataURL())
                canvas = null
                img = null
            }
            img.onerror = function () {
                reject(new Error("can not load the images"))
            }
        })
    }
}

export {
    getObjectType,
    convertObjectSubField,
    t,
    tools
}