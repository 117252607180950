import React from "react"
import apis,{httpClient} from "../../utils/NetUtils"
import * as icon from "@ant-design/icons"
import * as ui from  "antd"
import LoadUtil from "../../utils/LoadUtil";
import moment from "moment";

export default class MemberProfile extends React.Component {
    constructor (props) {
        super()
        this.state = {

        }
    }
    componentDidMount () {
        this.getMerchant()
    }
    /**
     * 获得商家信息
     */
    getMerchant () {
        var that = this
        apis.getMerchant().then(resp => {

        })
    }

    render () {
        var that = this
        return (
            <div style={{width:"700px"}}>
                <h1>信息管理</h1>
            </div>
        )
    }
}