/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2023/12/15
 */
import React, {useRef, useState} from "react"
import Cropper, { ReactCropperElement } from "react-cropper";
import "cropperjs/dist/cropper.css";


const ImagesCropper: React.FC = (props) => {
    const cropperRef = useRef(null);
    var imgUrl = "#"



    const onCrop = () => {
        const cropper = cropperRef.current?.cropper;
        try{
            props.onChange(cropper.getCroppedCanvas().toDataURL())
        } catch (e) {
            console.log(e.toString())
        }
    }

    return (
        <div>
            {props.src !== "" &&
            <Cropper
                src={props.src}
                style={{ width: "100%" }}
                // crossOrigin={""}
                checkCrossOrigin={false}
                // checkOrientation={false}
                autoCropArea = {0.9}
                guides={false}
                crop={onCrop}
                ref={cropperRef}
            />
            }
        </div>
    )
}


export default ImagesCropper