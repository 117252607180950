import React from "react"
import apis,{httpClient} from "../../utils/NetUtils"
import * as icon from "@ant-design/icons"
import * as ui from  "antd"
import LoadUtil from "../../utils/LoadUtil";
import moment from "moment";

const {Option} = ui.Select

export default class DigitalConvert extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            shipper: {},
            countrys: [],
            tableData: [],
            pagination: {total: 0, currPage: 1},
            keywords: {},
            tableLoading: false,
            editCurr: {},
            editShow: false,
            btnLoading: false
        }
    }
    componentDidMount() {
        this.getCountry()
        this.getShipper()
        this.GetTableData()
    }

    /**
     * 初始化
     * @param row
     */
    initial = (row) => {
        var dato = row ? JSON.parse(JSON.stringify(row)) : {}
        this.setState({
            editCurr: dato,
            editShow: true
        })
    }
    /**
     * 国家列表
     */
    getCountry(){
        var that = this
        apis.getCountrys().then(resp => {
            that.setState({
                countrys: resp.data.data
            })
        })
    }

    /**
     * 授权详情
     */
    getShipper() {
        var that = this
        apis.getShipperDetail(that.props.match.params.shipper).then(resp => {
            that.setState({
                shipper: resp.data.data
            })
        })
    }
    /**
     * 数据列表
     * @param pageNumber
     * @param pageSize
     * @param filter
     * @constructor
     */
    GetTableData=(pageNumber=1,pageSize=20)=>{
        var that = this
        var para = {}
        para.pageNumber = pageNumber
        para.pageSize = pageSize
        Object.assign(para, that.state.keywords)
        that.setState({
            tableLoading: true
        })
        apis.getPagination("/merchant/convert/formula/page",pageNumber,pageSize,that.state.keywords).then(resp=>{
            that.setState({
                tableLoading: false
            })
            var pageData = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableLoading: false,
                pagination: pageData,
                tableData: resp.data.data.data
            })
        })

    }
    /**
     * 发起搜索
     * @param params
     */
    makeSearch = (params) => {
        this.setState({
            keywords: params
        })
        this.GetTableData()
    }
    /**
     * 规则提交
     * @param params
     */
    handle = (params) => {
        var that = this
        if (that.state.editCurr.id) {
            params.id = that.state.editCurr.id
        }
        that.setState({
            btnLoading: true
        })
        apis.vlaueConvertAdmin(params).then(resp => {
            that.setState({
                btnLoading: false
            })
            if (resp.data.code === 200) {
                if (resp.data.data.code === 200) {
                    ui.message.success("提交成功")
                    that.GetTableData(that.state.pagination.currPage)
                } else {
                    ui.message.error(resp.data.data.msg)
                }
            } else {
                ui.message.error(resp.data.msg)
            }
        })

    }
    render() {
        var that = this
        var columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: '规则名称',
                dataIndex: "name"
            },
            {
                title: '转换公式',
                dataIndex: "formula"
            },
            {
                title: '最小预报值',
                dataIndex: "min"
            },
            {
                title: '最大预报值',
                dataIndex: "max"
            },
            {
                title: '转换标准',
                key: 'id',
                render: function (value, row, index) {
                    return row.convert_type === "weight" ? '重量' : '体积'
                }
            },
            {
                title: '影响区域',
                key: 'id',
                render: function (value, row, index) {
                    var keys = ["country_code", "province", "city", "suburb"]
                    var ks = []
                    keys.forEach(elem => {
                        if (row[elem]) {
                            ks.push(row[elem])
                        }
                    })
                    return ks.length ? ks.join(" / ") : '全域'
                }
            },
            {
                title: '规则管理',
                key: 'id',
                width:"120px",
                fixed: "right",
                render: function (value, row, index) {
                    return (
                        <ui.Button.Group>
                            <ui.Button onClick={() => {
                                that.initial(row)
                            }} type={'link'}>编辑</ui.Button>
                        </ui.Button.Group>
                    )
                }
            }
        ]
        return (
            <div style={{margin:"26px"}}>
                <ui.Breadcrumb>
                    <ui.Breadcrumb.Item>
                        <a href={"/"}>首页</a>
                    </ui.Breadcrumb.Item>
                    <ui.Breadcrumb.Item>
                        <a href={"/service/calc"}>称量规则</a>
                    </ui.Breadcrumb.Item>
                    <ui.Breadcrumb.Item>快递量值转换规则</ui.Breadcrumb.Item>
                </ui.Breadcrumb>
                <ui.PageHeader style={{marginTop:"26px"}} ghost={false}
                               title={`${that.state.shipper.name}量值转换规则`}
                               subTitle={"可对快递的重量与体积针对提报值批量偏移"}
                               extra={[<ui.Button type={"primary"} shape="round" onClick={() => {
                                   that.initial(null)
                               }}>新增规则</ui.Button>]}/>
                <div id={"toolbar"} style={{margin:"16px"}}>
                    <ui.Form onFinish={that.makeSearch} layout={"inline"}>
                        <ui.Form.Item name={"name"}>
                            <ui.Input placeholder={'规则名称...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item>
                            <ui.Button htmlType={"submit"} type={"primary"}>规则搜索</ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </div>
                <div style={{marginTop:"26px"}}>
                    <ui.Card>
                        <ui.Table
                            dataSource={that.state.tableData}
                            columns={columns}
                            loading={that.state.tableLoading}
                            pagination={{
                                total: that.state.pagination.total,
                                defaultPageSize:20,
                                defaultCurrent: that.state.pagination.currPage,
                                onChange: that.GetTableData
                            }}
                            rowKey={"id"}/>
                    </ui.Card>
                </div>
               <ui.Modal onCancel={() => {
                   that.setState({
                       editCurr: {},
                       editShow: false
                   })
               }} visible={that.state.editShow} width={"560px"} title={"量值规则管理器"} footer={null} destoryOnClose>
                   <ui.Form onFinish={that.handle} labelAlign={"left"} labelCol={{span:24}}>
                       <ui.Form.Item label={"规则名称"}
                                     rules={[{required:true,message:"给您的规则命名以方便查询查询与管理"}]}
                                     initialValue={that.state.editCurr.name}
                                     name={"name"}>
                           <ui.Input placeholder={"给您的规则命名以方便查询查询与管理"}/>
                       </ui.Form.Item>
                       <fieldset style={{border:"1px dotted #f2f2f2"}}>
                           <legend>作用域</legend>
                           <ui.Form.Item label={"国家"}
                                         rules={[{required:false,message:"国家"}]}
                                         initialValue={that.state.editCurr.country_code}
                                         name={"country_code"}>
                               <ui.Select placehplder={"请选择作用国家"}>
                                   {that.state.countrys.map(cc => {
                                       return (
                                           <Option value={cc.ccode} key={cc.id}>{cc.ShowName} / {cc.Name}</Option>
                                       )
                                   })}
                               </ui.Select>
                           </ui.Form.Item>
                           <ui.Form.Item label={"作用省 / 地区"}
                                         rules={[{required:false,message:"省 / 地区"}]}
                                         initialValue={that.state.editCurr.province}
                                         name={"province"}>
                               <ui.Input placeholder={"省 / 地区"}/>
                           </ui.Form.Item>
                           <ui.Form.Item label={"作用城市"}
                                         rules={[{required:false,message:"城市"}]}
                                         initialValue={that.state.editCurr.city}
                                         name={"city"}>
                               <ui.Input placeholder={"城市"}/>
                           </ui.Form.Item>
                           <ui.Form.Item label={"区县"}
                                         rules={[{required:false,message:"区县"}]}
                                         initialValue={that.state.editCurr.suburb}
                                         name={"suburb"}>
                               <ui.Input placeholder={"区县"}/>
                           </ui.Form.Item>
                       </fieldset>
                       <ui.Form.Item label={"规则类型"}
                                     rules={[{required:true,message:"给您的规则命名以方便查询查询与管理"}]}
                                     initialValue={that.state.editCurr.convert_type}
                                     name={"convert_type"}>
                           <ui.Radio.Group>
                               <ui.Radio value={"weight"}>重量</ui.Radio>
                               <ui.Radio value={"volume"}>体积</ui.Radio>
                           </ui.Radio.Group>
                       </ui.Form.Item>
                       <ui.Form.Item label={"规则可用"}
                                     rules={[{required:true,message:"给您的规则命名以方便查询查询与管理"}]}
                                     initialValue={that.state.editCurr.state}
                                     name={"state"}>
                           <ui.Radio.Group>
                               <ui.Radio value={true}>是</ui.Radio>
                               <ui.Radio value={false}>否</ui.Radio>
                           </ui.Radio.Group>
                       </ui.Form.Item>
                       <ui.Form.Item label={"最小预报值"}
                                     ules={[{required:true,message:"请设置最小值范围"}]}
                                     initialValue={that.state.editCurr.min}
                                     name={"min"}>
                           <ui.InputNumber style={{width:"100%"}} min={0} placeholder={"最小预报值"}/>
                       </ui.Form.Item>
                       <ui.Form.Item label={"最大预报值"}
                                     rules={[{required:true,message:"请设置最大值范围"}]}
                                     initialValue={that.state.editCurr.max}
                                     name={"max"}>
                           <ui.InputNumber style={{width:"100%"}} min={0} placeholder={"最大预报值"}/>
                       </ui.Form.Item>
                       <ui.Form.Item label={"转换规则"}
                                     rules={[{required:true,message:"请输入转换规则"}]}
                                     initialValue={that.state.editCurr.formula}
                                     name={"formula"}>
                           <ui.Input placeholder={"请输入转换规则"}/>
                       </ui.Form.Item>
                       <ui.Form.Item>
                           <ui.Button loading={that.state.btnLoading} shape={"round"} htmlType={"submit"} type={"primary"} block>提交转量规则</ui.Button>
                       </ui.Form.Item>
                   </ui.Form>
               </ui.Modal>
            </div>
        )
    }
}