/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2023/12/8
 */
import React, {Component} from "react";
import * as ui from "antd";
import {BellOutlined} from "@ant-design/icons";
import apis from "../../../utils/NetUtils";


const {TextArea} = ui.Input
const {Option} = ui.Select

export default class Product extends Component {
    constructor() {
        super();
        this.state = {
            tableData: [],
            paging: {total: 0, pageNumber: 1, pageSize: 20},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            submitting: false
        }
    }

    componentDidMount() {
        var that = this
        setTimeout(() => {
            that.tableDataSync(1)
        }, 200)
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    makeSearch = (params) => {
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.tableDataSync()
        }, 200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    tableDataSync = (pageNumber = 1) => {
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = 20;
        that.setState({
            tableLoad: true
        })
        Object.assign(para, that.state.searchKey)
        apis.getShopItems(para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                pageNumber: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                paging: curr
            })
        })
    }


    render() {
        var that = this

        const columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "所属店铺",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <ui.Button type={"dashed"} shape={"round"} size={"small"} danger>{row.shop.name}</ui.Button>
                    )
                }
            },
            {
                title: "产品品牌",
                key: "id",
                render: function (value, row, index) {
                    return <span style={{color:"#1b1b1b",fontWeight:"600"}}>{row.brand}</span>
                }
            },
            {
                title: "",
                key: "id",
                render: function (value, row, index) {
                    return <ui.Image width={"68px"} src={row.images.split("||")[0]}/>
                }
            },
            {
                title: "产品标题",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <div>
                            <h3>{row.title}</h3>
                            <small>
                                {row.sku}
                            </small>
                        </div>
                    )
                }
            },
            {
                title: "采购成本",
                key: "id",
                render: function (value, row, index) {
                    return <span style={{fontSize: "16px"}}>{row.cost_price}<span style={{fontSize: "12px",marginLeft:"6px"}}>{row.currency.toUpperCase()}</span></span>
                }
            },
            {
                title: "建议零售价",
                key: "id",
                render: function (value, row, index) {
                    return <span style={{fontSize: "16px"}}>{row.suggest_sale_price}<span style={{fontSize: "12px",marginLeft:"6px"}}>{row.currency.toUpperCase()}</span></span>
                }
            },
            {
                title: "销售价格",
                key: "id",
                render: function (value, row, index) {
                    return <span style={{fontSize: "16px"}}>{row.sale_price}<span style={{fontSize: "12px",marginLeft:"6px"}}>{row.currency.toUpperCase()}</span></span>
                }
            },
            {
                title: "可用",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <ui.Button shape={"round"} type={"circle"} style={{color: "#ffff", backgroundColor: row.state ? "darkcyan": "red"}}>{row.state ? '是' : '否'}</ui.Button>
                    )
                }
            },
            {
                title: "创建时间",
                key: "id",
                render: function (value, row, index) {
                    var dd = new Date(row.created_time)
                    return `${dd.toLocaleDateString()} ${dd.toLocaleTimeString()}`
                }
            },
            {
                title: "管理",
                key: "id",
                fixed: "right",
                render: function (value, row, index) {
                    return (
                        <span>
                            <a href={row.url} target={"__blank"}>立即查看</a>
                        </span>
                    )
                }
            }
        ]

        return (
            <div>
                <ui.PageHeader title="店铺商品管理" subTitle="店铺商品列表以及编辑管理" />
                <ui.Card>
                    <div style={{marginTop: '22px'}}>
                        <ui.Form onFinish={that.MakeSearch} layout={"inline"}>
                            <ui.Form.Item label={"产品标题"} name={"title__contains"}>
                                <ui.Input placeholder={"产品标题"}/>
                            </ui.Form.Item>
                            <ui.Form.Item label={"产品编码"} name={"mpn"}>
                                <ui.Input placeholder={"产品编码"}/>
                            </ui.Form.Item>
                            <ui.Form.Item label={"产品SKU"} name={"sku"}>
                                <ui.Input placeholder={"产品SKU"}/>
                            </ui.Form.Item>
                            <ui.Form.Item label={"原始产品主键"} name={"origin_id"}>
                                <ui.Input placeholder={"原始产品主键"}/>
                            </ui.Form.Item>
                            <ui.Form.Item>
                                <ui.Button type={"primary"} htmlType={"submit"}>快速搜索</ui.Button>
                            </ui.Form.Item>
                        </ui.Form>
                    </div>
                </ui.Card>

                <div style={{marginTop: '22px'}}>
                    <ui.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{
                            total: this.state.paging.total,
                            defaultPageSize: 20,
                            defaultCurrent: this.state.paging.pageNumber,
                            onChange: this.tableDataSync
                        }}/>
                </div>

                <ui.Modal visible={that.state.editShow} title={'定时任务执行客户端名称'} onCancel={() => {
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        submitting: false
                    })
                }} footer={null} destroyOnClose>
                    <ui.Form onFinish={(params => {
                        that.setState({
                            submitting: true
                        })
                        if (that.state.editCurr.id) {
                            params.id = that.state.editCurr.id
                        }
                        apis.schedulerExecuteRegistry(params).then(resp => {
                            that.setState({
                                submitting: false
                            })
                            if (resp.data.code === 200 && resp.data.data.code === 200) {
                                ui.message.success("提交信息成功")
                                that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                                that.setState({
                                    submitting: false,
                                    editShow: false,
                                    editCurr: {}
                                })
                            } else {
                                ui.message.error(resp.data.data.msg ? resp.data.data.msg : resp.data.msg)
                            }
                        })
                    })} labelCol={{span: 24}} labelAlign={"left"} initialValues={that.state.editCurr}>
                        <ui.Form.Item label={"执行客户端名称"} name={"name"}
                                      rules={[{required: true, message: '执行客户端名称'}]}>
                            <ui.Input placeholder={"执行客户端名称"} disabled={that.state.editCurr.id}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"执行客户端名称"} name={"state"}
                                      rules={[{required: true, message: '执行客户端名称'}]}>
                            <ui.Radio.Group>
                                <ui.Radio value={true}>可用</ui.Radio>
                                <ui.Radio value={false}>禁用</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"执行器"} name={"value"} help="地址样式:http://ip:8999"
                                      rules={[{required: true, message: '执行器'}]}>
                            <ui.Input placeholder={"执行器"}/>
                        </ui.Form.Item>
                        <ui.Form.Item>
                            <ui.Button htmlType={"submit"} loading={that.state.submitting} type={"primary"}
                                       block>立即提交
                            </ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>
            </div>
        )
    }
}