import React from "react"
import apis,{httpClient} from "../../../utils/NetUtils"
import * as icon from "@ant-design/icons"
import * as ui from  "antd"
import {Loading} from "@icon-park/react";
import LoadUtil from "../../../utils/LoadUtil";


export default class TransformsList extends React.Component{
    constructor() {
        super();
        this.state = {
            channels: [],
            editCurr: {},
            editShow: false,
            editLoading: false,
            auths:[],
            selectedChannel: {},
            keywords: {},
            pagination: {
                total: 0,
                currPage: 1
            }
        }
    }

    componentDidMount() {
        this.channelSync()
        this.tableDataSync(1)
    }

    /**
     * 加载授权列表
     */
    verifiedAuthSync (filter) {
        var that = this
        LoadUtil.StartLoading()
        apis.verifiedAuthorizationsSync(filter).then(res => {
            LoadUtil.HasLoaded()
            that.setState({
                auths: res.data.data.data
            })
        })
    }

    /**
     * 加载转换通道
     */
    channelSync () {
        var that = this
        apis.transformChannelSync({}).then(res => {
            that.setState(({
                channels: res.data.data
            }))
        })
    }

    /**
     * 数据列表
     * @param pageNumber
     * @param pageSize
     * @param filter
     * @constructor
     */
    tableDataSync=(pageNumber=1,pageSize=20)=>{
        var that = this
        var para = {}
        para.pageNumber = pageNumber
        para.pageSize = pageSize
        that.setState({
            tableLoading: true
        })
        Object.assign(para,that.state.keywords)
        apis.syncUserSchedulers().then(resp=>{
            that.setState({
                tableLoading: false
            })
            console.log(resp.data.data)
            var pageData = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableLoading: false,
                pagination: pageData,
                tableData: resp.data.data.data
            })
        })

    }

    render() {
        const  that = this
        const columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: '任务名称',
                dataIndex: "name"
            },
            {
                title: '任务备注',
                dataIndex: "remark"
            },
            {
                title: '订阅通道',
                key: "id",
                render: function (value, row, index) {
                    return (
                        <div>
                            <h4>{row.channel.name}</h4>
                        </div>
                    )
                }
            },
            {
                title: '付费订单号',
                key: "id",
                render: function (value, row, index) {
                    return row.order_item === "" ? "免费" : row.order_item
                }
            },
            {
                title: '来源授权ID',
                key: "id",
                render: function (value, row, index) {
                    return row.origin_authorization
                }
            },
            {
                title: '目标授权ID',
                key: "id",
                render: function (value, row, index) {
                    return row.target_authorization
                }
            },
            {
                title: '创建时间',
                key: "id",
                render: function (value, row, index) {
                    var dd = new Date(row.created_time)
                    return <span>{dd.toLocaleDateString()}  {dd.toLocaleTimeString()}</span>
                }
            },
            {
                title: '最后同步',
                key: "id",
                render: function (value, row, index) {
                    var dd = new Date(row.last_sync)
                    return <span>{dd.toLocaleDateString()}  {dd.toLocaleTimeString()}</span>
                }
            },
            {
                title: '禁用',
                key: "id",
                render: function (value, row, index) {
                    return <ui.Button size={"small"} shape={"circle"} danger>{row.state ? "否" : "是"}</ui.Button>
                }
            },
            {
                title: '管理',
                key: "id",
                render: function (value, row, index) {
                    return (
                        <span>
                            <ui.Button type={"link"} size={"small"} onClick={() => {
                                var params = {}
                                params.grant_id__in = [Number(row.channel.origin_grant),Number(row.channel.target_grant)]
                                that.verifiedAuthSync(params)
                                var data = JSON.parse(JSON.stringify(row))
                                data.channel_id = data.channel.id
                                that.setState({
                                    selectedChannel: row.channel,
                                    editCurr: data,
                                    editShow: true,
                                    editLoading: false
                                })
                            }}>编辑</ui.Button>
                            {row.channel.feed_base_url!==""　&&
                                <ui.Button type={"link"} size={"small"} href={`${row.channel.feed_base_url}${row.id}`} target={"_blank"}>订阅地址</ui.Button>
                            }
                        </span>
                    )
                }
            }
        ]
        return (
            <div style={{margin:"26px"}}>
                <ui.Breadcrumb>
                    <ui.Breadcrumb.Item>
                        <a href={"/"}>首页</a>
                    </ui.Breadcrumb.Item>
                    <ui.Breadcrumb.Item>自动化同步任务管理</ui.Breadcrumb.Item>
                </ui.Breadcrumb>
                <ui.PageHeader style={{marginTop:"26px"}} ghost={false}
                               title={`计划任务管理`} extra={[
                                   <ui.Button type={"primary"} shape={"round"} onClick={() => {
                                       that.setState({
                                           editCurr: {},
                                           editShow: true,
                                           editLoading: false
                                       })
                                   }}>新增任务</ui.Button>
                ]}/>
                <div id={"toolbar"} style={{margin:"16px"}}>
                    <ui.Form  onFinish={param => {
                        that.setState({
                            keywords: param
                        })
                        that.tableDataSync(1)
                    }} layout={"inline"}>
                        <ui.Form.Item name={"name__icontains"} label="任务名称">
                            <ui.Input placeholder={'任务名称...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item>
                            <ui.Button htmlType={"submit"} type={"primary"}>任务搜索</ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </div>
                <div style={{marginTop:"26px"}}>
                    <ui.Card>
                        <ui.Table
                            dataSource={that.state.tableData}
                            columns={columns}
                            loading={that.state.tableLoading}
                            pagination={{
                                total: that.state.pagination.total,
                                defaultPageSize:20,
                                defaultCurrent: that.state.pagination.currPage,
                                onChange: that.tableDataSync
                            }}
                            rowKey={"id"}/>
                    </ui.Card>
                </div>
                <ui.Modal open={that.state.editShow} footer={null} onCancel={() => {
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        editLoading: false
                    })
                }} destroyOnClose>
                    <h2>自动化任务订阅管理</h2>
                    <small>默认任务主任务每12小时一次，若有自定义定阅，请填入自定义订阅的订单产品ID号</small>
                    <ui.Divider/>
                    <ui.Form onFinish={params => {
                        if (that.state.editCurr.id) {
                            params.id = that.state.editCurr.id
                        }
                        that.setState({
                            editLoading: true
                        })
                        apis.handleSchedulerSubscribe(params).then(res => {
                            that.setState({
                                editLoading: false
                            })
                            if (res.data.code === 200 && res.data.data.code === 200) {
                                ui.message.success("提交订阅信息成功")
                                that.setState({
                                    editCurr: {},
                                    editShow: false,
                                    editLoading: false
                                })
                                that.tableDataSync(that.state.editCurr.id ? that.state.pagination.currPage : 1)
                            } else {
                                ui.message.error(res.data.data.msg ? res.data.data.msg : res.data.msg)
                            }
                        })
                    }} initialValues={that.state.editCurr} labelAlign={"left"} labelCol={{span: 24}}>
                        <ui.Form.Item label={"任务名称"} name={"name"} rules={[{required:true,message:"任务名称"}]}>
                            <ui.Input placeholder={"请为你订阅的自动任务命名"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"任务备注"} name={"remark"} rules={[{required:false,message:"任务备注"}]}>
                            <ui.Input placeholder={"任务备注"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"原始资源地址"} name={"origin_base_url"} rules={[{required:true,message:"原始资源地址"}]} help={"如: https://www.example.com"}>
                            <ui.Input placeholder={"原始资源地址"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"可用"} name={"state"} rules={[{required:true,message:"可用"}]}>
                            <ui.Radio.Group>
                                <ui.Radio value={true}>可用</ui.Radio>
                                <ui.Radio value={false}>禁用</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"订阅资源"} name={"channel_id"} rules={[{required:true,message:"订阅资源"}]}>
                            <ui.Select onChange={(v,event) => {
                                var params = {}
                                params.grant_id__in = [Number(event.data.origin_grant),Number(event.data.target_grant)]
                                that.verifiedAuthSync(params)
                                that.setState({
                                    selectedChannel: event.data
                                })
                            }} placeholder={"请从下列资源中选择您所订阅的资源"} disabled={that.state.editCurr.id}>
                                {that.state.channels.map(item => {
                                    return (
                                        <ui.Select.Option key={item.id} value={item.id} data={item}>{item.name}</ui.Select.Option>
                                    )
                                })}
                            </ui.Select>
                        </ui.Form.Item>
                        {that.state.selectedChannel.id &&
                        <span>
                            <ui.Form.Item label={"来源渠道"} name={"origin_authorization"} rules={[{required:true,message:"来源渠道"}]}>
                            <ui.Select placeholder={"请从下列资源中选择您的来源渠道"} disabled={that.state.editCurr.id}>
                                {that.state.auths.map(item => {
                                    if (item.grant.id === Number(that.state.selectedChannel.origin_grant)) {
                                        return (
                                            <ui.Select.Option key={item.id} value={item.id} data={item}>{item.grant.name} / {item.name}</ui.Select.Option>
                                        )
                                    }
                                })}
                            </ui.Select>
                        </ui.Form.Item>
                        <ui.Form.Item label={"目标渠道"} name={"target_authorization"} rules={[{required:true,message:"目标渠道"}]}>
                            <ui.Select placeholder={"请从下列资源中选择您的目标渠道"} disabled={that.state.editCurr.id}>
                                {that.state.auths.map(item => {
                                    if (item.grant.id === Number(that.state.selectedChannel.target_grant)) {
                                        return (
                                            <ui.Select.Option key={item.id} value={item.id} data={item}>{item.grant.name} / {item.name}</ui.Select.Option>
                                        )
                                    }
                                })}
                            </ui.Select>
                        </ui.Form.Item>
                        </span>
                        }
                        <ui.Form.Item label={"订阅订单产品号"} name={"order_item"} rules={[{required:false,message:"订阅订单产品号"}]} help={"如果有订阅付费服务请在此配置"}>
                            <ui.Input placeholder={"订阅订单产品号"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"任务说明"} name={"brief"} rules={[{required:true,message:"任务说明"}]}>
                            <ui.Input.TextArea rows={6} placeholder={"任务说明"}/>
                        </ui.Form.Item>
                        <ui.Form.Item style={{textAlign:'right'}}>
                            <ui.Button type="primary" shape="round" loading={that.state.editLoading} htmlType="submit">提交订阅信息</ui.Button>
                        </ui.Form.Item>

                    </ui.Form>
                </ui.Modal>
            </div>
        )
    }
}