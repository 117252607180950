import React, {Component} from "react";
import {Route} from "react-router-dom";

export default class RouterMapping extends Component{
    constructor(props) {
        super(props);
    }
    render() {
        return (
            this.props.routers.map((item,index) => {
                if (item.children) {
                    return (
                        <Route key={index} path={item.url} component={item.component} exact={item.exact}>
                            <RouterMapping routers={item.children}/>
                        </Route>
                    )
                } else {
                    return (
                        <Route key={index} path={item.path} component={item.component} exact={item.exact}/>
                    )
                }
            })
        )
    }
}
