import React from 'react'
import ReactDOM from 'react-dom/client';
import * as UI from "antd";
import App from "../App";

var StartLoading=(words=undefined)=>{
    let doc=document.createElement("div");
    doc.setAttribute("id","loading");
    doc.style.width='100vw';
    doc.style.height='100vh';
    doc.style.zIndex=9999999999;
    doc.style.position='fixed';
    doc.style.top=0;
    doc.style.left=0;
    doc.style.textAlign='center';
    doc.style.paddingTop='35vh';
    document.body.appendChild(doc);
    // ReactDOM.render(
    //     <UI.Spin tip="请求中..." size="large"/>,
    //     doc
    // );
    const root = ReactDOM.createRoot(doc);
    root.render(<UI.Spin tip={words ? words : "请求中..."} size="large"/>,);
}

var HasLoaded=()=> {
    try {
        document.body.removeChild(document.getElementById("loading"));
    } catch (e) {
        console.log(e);
    }
}

var LoadUtil = {
    StartLoading: StartLoading,
    HasLoaded: HasLoaded
}

export default LoadUtil
export {
    StartLoading,
    HasLoaded
}