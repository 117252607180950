import React from "react"
import {BrowserRouter, Switch, Route} from "react-router-dom"
import RouterMapping from "../components/RouterMapping";
import Sso from "../pages/member/Sso";
import InitialShipperGroup from "../pages/member/InitialShipperGroup"
import PrimaryPage from "../pages/PrimaryPage";
import ValueConvert from "../pages/services/ValueConvert";
import TransPage from "../pages/TransPage";
import DigitalConvert from "../pages/tools/DigitalConverts";
import LabelAccess from "../pages/services/LabelAccess";
import Shippers from "../pages/resources/Shippers";
import Developer from "../pages/member/Developer";
import Labels from "../pages/datos/Labels";
import MemberProfile from "../pages/member/Profile";
import GoogleShopping from "../pages/spread/GoogleShopping";
import Transforms from "../pages/com/scheduler/TransformsList";
import TransformsList from "../pages/com/scheduler/TransformsList";
import CatalogProducts from "../pages/spread/CatalogProducts";
import PriceLayer from "../pages/bigdata/price/PriceLayer";
import LabelDataSimple from '../pages/datos/extra/LabelDataSimple'


var routers = [
    {
        path: '/sso',
        component: Sso,
        exact: true,
    },
    {
        path:'/initial',
        component: InitialShipperGroup,
        exact: true,
    },
    {
        path:'/spread',
        component: TransPage,
        exact: true,
        children: [
            {
                path:'/spread/google_shopping',
                component: GoogleShopping,
            },
            {
                path:'/spread/catalog_product',
                component: CatalogProducts,
            }
        ]
    },
    {
        path:'/bigdata',
        component: TransPage,
        exact: false,
        children: [
            {
                path:'/bigdata/price_tracking',
                component: PriceLayer,
                exact: false,
            }
        ]
    },
    {
        path:'/parcel',
        component: TransPage,
        exact: true,
        children: [
            {
                path:'/parcel/carrier',
                component: LabelAccess,
            },
            {
                path:'/parcel/value_converter',
                component: ValueConvert,
            },
            {
                path:'/parcel/orders',
                component: Labels,
            },
            {
                path:'/parcel/simple_data',
                component: LabelDataSimple,
            }
        ]
    },
    {
        path:'/task',
        component: TransPage,
        children: [
            {
                path:'/task/shop_sync',
                component: TransformsList,
            }
        ]
    },
    {
        path:'/carrier',
        component: TransPage,
        children: [
            {
                path:'/carrier/:carrierCode',
                component: Shippers,
            }
        ]
    },
    {
        path:'/tools',
        component: TransPage,
        children: [
            {
                path:'/tools/digital_convert/:shipper',
                component: DigitalConvert,
            }
        ]
    },
    {
        path:'/account',
        component: TransPage,
        exact: true,
        children: [
            {
                path:'/account/developer',
                component: Developer,
            },
            {
                path:'/account/profile',
                component: MemberProfile,
            }
        ]
    },
    {
        path:'/',
        component: LabelAccess,
        exact: true
    },
]

export default class RouterView extends React.Component{
    render() {
        return (
            <div id="main">
                <BrowserRouter basename={"/"}>
                    <Switch>
                        <RouterMapping routers={routers}/>
                    </Switch>
                </BrowserRouter>
            </div>
        )
    }
}