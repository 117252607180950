import React from "react"
import RouterView from "../router/Router";

export default class TransPage extends React.Component{
    render() {
        return (
            <div style={{margin:"16px"}}>
                <RouterView/>
            </div>
        )
    }
}