import React from "react"
import apis,{httpClient} from "../../utils/NetUtils"
import * as icon from "@ant-design/icons"
import * as ui from  "antd"
import LoadUtil from "../../utils/LoadUtil";
import moment from "moment";

const {Option} = ui.Select

export default class Labels extends React.Component{
    constructor() {
        super();
        this.state = {
            tableData: [],
            pagination: {total: 0, currPage: 1},
            keywords: {},
            tableLoading: false,
            editCurr: {},
            editShow: false,
            btnLoading: false
        }
    }
    componentDidMount() {
        this.GetTableData(1)
    }
    /**
     * 数据列表
     * @param pageNumber
     * @param pageSize
     * @param filter
     * @constructor
     */
    GetTableData=(pageNumber=1,pageSize=20)=>{
        var that = this
        var para = {}
        para.pageNumber = pageNumber
        para.pageSize = pageSize
        Object.assign(para, that.state.keywords)
        that.setState({
            tableLoading: true
        })
        apis.getPagination("/label/page",pageNumber,pageSize,that.state.keywords).then(resp=>{
            that.setState({
                tableLoading: false
            })
            var pageData = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableLoading: false,
                pagination: pageData,
                tableData: resp.data.data.data
            })
        })

    }
    /**
     * 发起搜索
     * @param params
     */
    makeSearch = (params) => {
        this.setState({
            keywords: params
        })
        this.GetTableData()
    }
    render() {
        var that = this
        var columns = [
            {
                title: 'ID',
                dataIndex: 'id'
            },
            {
                title: '报错',
                key:'id',
                width:"260px",
                render: function (value, row, index) {
                    return (
                        <div style={{color:"red"}}>
                            <span dangerouslySetInnerHTML={{__html:row.errors ? row.errors : '无'}}></span>
                        </div>
                    )
                }
            },
            {
                title: '店铺订单号',
                key:'id',
                render: function (value, row, index) {
                    return row.shop_order_id ? row.shop_order_id  : '无'
                }
            },
            {
                title: '关联订单号',
                key:'id',
                render: function (value, row, index) {
                    return row.ref_order_number ? row.ref_order_number  : '无'
                }
            },
            {
                title: '收件人',
                key:'id',
                render: function (value, row, index) {
                    return JSON.parse(row.recipient).contract
                }
            },
            {
                title: '采购通道',
                key:'id',
                render: function (value, row, index) {
                    var name = row.snap_shipper && row.snap_shipper !== ''  ? JSON.parse(row.snap_shipper).name : '待确认'
                    return name
                }
            },
            {
                title: '跟踪号',
                key:'id',
                render: function (value, row, index) {
                    return row.tracking_number ? row.tracking_number : '待产生'
                }
            },
            {
                title: '交换单号',
                key:'id',
                render: function (value, row, index) {
                    return row.exchange_tracking_number ? row.exchange_tracking_number : '待产生'
                }
            },
            {
                title: '采购时间',
                key:'id',
                render: function (value, row, index) {
                    return moment(row.create_at).format("YYYY-MM-DD HH:mm:ss")
                }
            },
            {
                title: '面单管理',
                key: 'id',
                width:"140px",
                fixed: "right",
                render: function (value, row, index) {
                    return (
                        <ui.Button.Group>
                            <ui.Button onClick={() => {
                                LoadUtil.StartLoading()
                                apis.rePurchaseLabel(row.id).then(resp => {
                                    LoadUtil.HasLoaded()
                                    if (resp.data.code === 200) {
                                        if (resp.data.data.ok) {
                                            ui.message.success("任务提交成功,待系统自动处理完成")
                                        } else {
                                            ui.message.error(resp.data.data.msg)
                                        }
                                    } else {
                                        ui.message.error(resp.data.msg)
                                    }
                                })
                            }} type={'link'}>重新发起采购任务</ui.Button>
                            <ui.Button onClick={() => {
                                LoadUtil.StartLoading()
                                apis.reSendNotify(row.id).then(resp => {
                                    LoadUtil.HasLoaded()
                                    if (resp.data.code === 200) {
                                        if (resp.data.data.ok) {
                                            ui.message.success("任务提交成功,待系统自动处理完成")
                                        } else {
                                            ui.message.error(resp.data.data.msg)
                                        }
                                    } else {
                                        ui.message.error(resp.data.msg)
                                    }
                                })
                            }} type={'link'}>手动推送面单</ui.Button>
                            <ui.Button onClick={() => {
                                LoadUtil.StartLoading()
                                apis.labelReAsync(row.id).then(resp => {
                                    LoadUtil.HasLoaded()
                                    if (resp.data.code === 200) {
                                        if (resp.data.data.ok) {
                                            ui.message.success("任务提交成功,待系统自动处理完成")
                                        } else {
                                            ui.message.error(resp.data.data.msg)
                                        }
                                    } else {
                                        ui.message.error(resp.data.msg)
                                    }
                                })
                            }} type={'link'}>手动拉取面单</ui.Button>
                            {row.label.length >0 &&
                            <ui.Button type={'link'} href={row.label} target={"_blank"}>下载电子面单</ui.Button>
                            }
                            {row.exchange_label.length >0 &&
                            <ui.Button type={'link'} href={row.exchange_label} target={"_blank"}>下载交换电子面单</ui.Button>
                            }
                            {row.document.length >0 &&
                            <ui.Button type={'link'} href={row.document} target={"_blank"}>下载电子发票</ui.Button>
                            }
                        </ui.Button.Group>
                    )
                }
            }
        ]
        return (
            <div style={{margin:"26px"}}>
                <ui.Breadcrumb>
                    <ui.Breadcrumb.Item>
                        <a href={"/"}>首页</a>
                    </ui.Breadcrumb.Item>
                    <ui.Breadcrumb.Item>电子面单采购记录</ui.Breadcrumb.Item>
                </ui.Breadcrumb>
                <ui.PageHeader style={{marginTop:"26px"}} ghost={false}
                               title={`电子面单采购记录`}/>
                <div id={"toolbar"} style={{margin:"16px"}}>
                    <ui.Form onFinish={that.makeSearch} layout={"inline"}>
                        <ui.Form.Item name={"shop_order_id"} label="店铺订单号">
                            <ui.Input placeholder={'店铺订单号...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item name={"ref_order_number"} label="关联订单号">
                            <ui.Input placeholder={'关联订单号...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item name={"tracking_number"} label="跟踪号">
                            <ui.Input placeholder={'跟踪号...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item name={"exchance_tracking_number"} label="交换跟踪号">
                            <ui.Input placeholder={'交换跟踪号...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item>
                            <ui.Button htmlType={"submit"} type={"primary"}>采购搜索</ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </div>
                <div style={{marginTop:"26px"}}>
                    <ui.Card>
                        <ui.Table
                            dataSource={that.state.tableData}
                            columns={columns}
                            loading={that.state.tableLoading}
                            pagination={{
                                total: that.state.pagination.total,
                                defaultPageSize:20,
                                defaultCurrent: that.state.pagination.currPage,
                                onChange: that.GetTableData
                            }}
                            rowKey={"id"}/>
                    </ui.Card>
                </div>
            </div>
        )
    }
}