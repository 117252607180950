import React from "react"
import apis,{httpClient} from "../../utils/NetUtils"
import * as icon from "@ant-design/icons"
import * as ui from  "antd"
import LoadUtil from "../../utils/LoadUtil";
import moment from "moment";

const {Option} = ui.Select

export default class ValueConvert extends React.Component{
    constructor() {
        super();
        this.state = {
            tableData: [],
            pagination: {total: 0, currPage: 1},
            keywords: {},
            tableLoading: false
        }
    }
    componentDidMount() {
        this.GetTableData(1)
    }

    /**
     * 数据列表
     * @param pageNumber
     * @param pageSize
     * @param filter
     * @constructor
     */
    GetTableData=(pageNumber=1,pageSize=20)=>{
        var that = this
        var para = {}
        para.pageNumber = pageNumber
        para.pageSize = pageSize
        Object.assign(para, that.state.keywords)
        that.setState({
            tableLoading: true
        })
        apis.getPagination("/merchant/shippers",pageNumber,pageSize,that.state.keywords).then(resp=>{
            that.setState({
                tableLoading: false
            })
            var pageData = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableLoading: false,
                pagination: pageData,
                tableData: resp.data.data.data
            })
        })

    }
    /**
     * 发起搜索
     * @param params
     */
    makeSearch = (params) => {
        this.setState({
            keywords: params
        })
        this.GetTableData()
    }
    render() {
        var that = this
        var columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: '快递名称',
                dataIndex: "name"
            },
            {
                title: "快递渠道",
                key: "id",
                render: function (value, row, index) {
                    return row.grant.name
                }
            },
            {
                title: '创建时间',
                key: 'id',
                render: function (value, row, index) {
                    return moment(row.createAt).format("yyyy-MM-DD")
                }
            },
            {
                title: '规则管理',
                key: 'id',
                render: function (value, row, index) {
                    return (
                        <ui.Button.Group>
                            <ui.Button href={`/tools/digitalconvert/${row.id}`} type={'link'}>量值转换</ui.Button>
                        </ui.Button.Group>
                    )
                }
            }
        ]
        return (
            <div ic={"container"}style={{margin:"26px"}}>
                <ui.PageHeader title={"预报值转换规则"}/>
                <div id={"toolbar"} style={{margin:"16px"}}>
                    <ui.Form onFinish={that.makeSearch} layout={"inline"}>
                        <ui.Form.Item name={"id"}>
                            <ui.Input placeholder={'快递ID号...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item name={"name__container"}>
                            <ui.Input placeholder={'快递名称...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item name={"grant__name__icontainer"}>
                            <ui.Input placeholder={'渠道名称...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item>
                            <ui.Button htmlType={"submit"} type={"primary"}>授权搜索</ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </div>
                <ui.Row gutter={16} style={{padding:"16px"}} className={'cart-list'}>
                    {that.state.tableData.map(item => {
                        return (
                            <ui.Col key={item.id} span={6}>
                                <ui.Card
                                actions={[<ui.Button href={`/tools/digitalconvert/${item.id}`} type={"link"}>计量规则管理</ui.Button>]}
                                >
                                    <ui.Card.Meta title={item.name} description={moment(item.createAt).format("yyyy-MM-DD")}></ui.Card.Meta>
                                </ui.Card>
                            </ui.Col>
                        )
                    })}
                </ui.Row>
                <div style={{display:"block",marginTop:"28px"}}>
                    <ui.Pagination onChange={that.GetTableData} total={that.state.pagination.total} pagesize={24} currency={that.state.pagination.currPage}/>
                </div>
            </div>
        )
    }
}