import React from "react"
import apis,{httpClient} from "../../utils/NetUtils"
import * as icon from "@ant-design/icons"
import * as ui from  "antd"
import LoadUtil from "../../utils/LoadUtil";
import moment from "moment";

const {TextArea} = ui.Input

export default class developer extends React.Component{
    constructor() {
        super();
        this.state = {
            tableData: [],
            pagination: {total: 0, currPage: 1},
            keywords: {},
            tableLoading: false,
            editCurr: {},
            editShow: false,
            btnLoading: false,
            token: "",
            tokenShow: false
        }
    }
    componentDidMount() {
        this.GetTableData()
    }

    /**
     * 编辑初始化
     * @param row
     */
    initial = (row) => {
        this.setState({
            editCurr: row ? row : {},
            editShow: true
        })
    }
    /**
     * 数据列表
     * @param pageNumber
     * @param pageSize
     * @param filter
     * @constructor
     */
    GetTableData=(pageNumber=1,pageSize=20)=>{
        var that = this
        var para = {}
        para.pageNumber = pageNumber
        para.pageSize = pageSize
        Object.assign(para, that.state.keywords)
        that.setState({
            tableLoading: true
        })
        apis.getPagination("/developer/list",pageNumber,pageSize,that.state.keywords).then(resp=>{
            that.setState({
                tableLoading: false
            })
            var pageData = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableLoading: false,
                pagination: pageData,
                tableData: resp.data.data.data
            })
        })

    }
    /**
     * 发起搜索
     * @param params
     */
    makeSearch = (params) => {
        this.setState({
            keywords: params
        })
        this.GetTableData()
    }
    /**
     * 规则提交
     * @param params
     */
    handle = (params) => {
        var that = this
        if (that.state.editCurr.id) {
            params.id = that.state.editCurr.id
        }
        that.setState({
            btnLoading: true
        })
        apis.developerModify(params).then(resp => {
            that.setState({
                btnLoading: false
            })
            if (resp.data.code === 200) {
                if (resp.data.data.code === 200) {
                    ui.message.success("提交成功")
                    if (resp.data.data.token) {
                        that.setState({
                            token: resp.data.data.token,
                            tokenShow: true,
                            editShow: false
                        })
                    }
                    that.GetTableData(that.state.pagination.currPage)
                } else {
                    ui.message.error(resp.data.data.msg)
                }
            } else {
                ui.message.error(resp.data.msg)
            }
        })

    }
    render() {
        var that = this
        var columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "名称",
                dataIndex: "name"
            },
            {
                title: "推送地址",
                dataIndex: "notify_url"
            },
            {
                title: "应用介绍",
                dataIndex: "explain"
            },
            {
                key: 'id',
                title: "应用管理",
                width:"120px",
                fixed: "right",
                render: function (value, row, index) {
                    return (
                        <ui.Button.Group>
                            <ui.Button onClick={() => {
                                that.initial(row)
                            }} type={'link'}>编辑</ui.Button>
                        </ui.Button.Group>
                    )
                }
            }
        ]
        return (
            <div style={{margin:"26px"}}>
                <ui.Breadcrumb>
                    <ui.Breadcrumb.Item>
                        <a href={"/"}>首页</a>
                    </ui.Breadcrumb.Item>
                    <ui.Breadcrumb.Item>开发者帐户</ui.Breadcrumb.Item>
                </ui.Breadcrumb>
                <ui.PageHeader style={{marginTop:"26px"}} ghost={false}
                               extra={[<ui.Button type={"primary"} shape="round" onClick={() => {
                                   that.initial(null)
                               }}>新增开发者</ui.Button>]}
                               title={`开发者帐户`}/>
                <div id={"toolbar"} style={{marginTop:"16px"}}>
                    <ui.Form onFinish={that.makeSearch} layout={"inline"}>
                        <ui.Form.Item name={"name"}>
                            <ui.Input placeholder={'开者名称...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item>
                            <ui.Button htmlType={"submit"} type={"primary"}>开发者搜索</ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </div>
                <div style={{marginTop:"26px"}}>
                    <ui.Card>
                        <ui.Table
                            dataSource={that.state.tableData}
                            columns={columns}
                            loading={that.state.tableLoading}
                            pagination={{
                                total: that.state.pagination.total,
                                defaultPageSize:20,
                                defaultCurrent: that.state.pagination.currPage,
                                onChange: that.GetTableData
                            }}
                            rowKey={"id"}/>
                    </ui.Card>
                </div>
                <ui.Modal onCancel={() => {
                    that.setState({
                        editCurr: {},
                        editShow: false
                    })
                }} visible={that.state.editShow} width={"560px"} title={"量值规则管理器"} footer={null} destoryOnClose>
                    <ui.Form onFinish={that.handle} labelAlign={"left"} labelCol={{span:24}}>
                        <ui.Form.Item label={"应用名称"}
                                      rules={[{required:true,message:"应用名称"}]}
                                      initialValue={that.state.editCurr.name}
                                      name={"name"}>
                            <ui.Input placeholder={"应用名称"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"可用"}
                                      rules={[{required:true,message:"可用"}]}
                                      initialValue={that.state.editCurr.state}
                                      name={"state"}>
                            <ui.Radio.Group>
                                <ui.Radio value={true}>是</ui.Radio>
                                <ui.Radio value={false}>否</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"通知地址"}
                                      rules={[{required:true,message:"通知地址"}]}
                                      initialValue={that.state.editCurr.notify_url}
                                      name={"notify_url"}>
                            <ui.Input placeholder={"应用名称"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"应用介绍"}
                                      rules={[{required:true,message:"应用介绍"}]}
                                      initialValue={that.state.editCurr.explain}
                                      name={"explain"}>
                            <TextArea rows={6} placeholder={"应用名称"}/>
                        </ui.Form.Item>
                        <ui.Form.Item>
                            <ui.Button loading={that.state.btnLoading} shape={"round"} htmlType={"submit"} type={"primary"} block>提交转量规则</ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>
                <ui.Modal onCancel={() => {
                    that.setState({
                        tokenShow: false
                    })
                }} visible={that.state.tokenShow} width={"560px"} title={"量值规则管理器"} footer={null} destoryOnClose>
                    <h1 style={{fontWeight:"bolder",fontSize:"26px",color:"red"}}>请妥善保存如下token,此参数后续不再重现</h1>
                    <div style={{height:"50vh",display:"flex",justifyContent:"center",alignItems:"center"}}>
                        <small style={{fontSize:"16px"}}>{that.state.token}</small>
                    </div>
                </ui.Modal>
            </div>
        )
    }
}