import axios from "axios"

axios.defaults.withCredentials = false

var apiBase = process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:9668/' : 'https://api.hottol.com/qdsi/'

// var ssoUrl = "https://graph.hottol.com/sign?client=6099327415011311616&redriect=https://ecyun.tuiex.xyz/"

var ssoBaseUrl = "https://graph.hottol.com/sign"

var httpClient = (entpoint, data,service="api", version = '/v1', httpUrl = null) => {
    var api = httpUrl ? httpUrl : `${apiBase}${service}${version}${entpoint}`
    return axios.post(api, data ? data : {})
}

var defaultClientId = '6099327415011311616'

/**
 * 获取当前请求终端的ID,若存在则使用当前用户存储ID，若没有，则使用默认ID，解决私有化部署的问题
 */
function getCurrentClient() {
    return localStorage.getItem("appId") ? localStorage.getItem("appId") : defaultClientId
}

function generateSsoUrl () {
    return `${ssoBaseUrl}?client=${getCurrentClient()}&redirectUrl=${window.location.href}`
}

/**
 * 访问前拦截
 */
axios.interceptors.request.use(config => {
    if (localStorage.getItem("appToken")) {
        config.headers.Authorization = `appToken ${localStorage.getItem("appToken")}`
    }
    return config
})


/**
 * 响应拦截,非登陆状态跳转
 */
axios.interceptors.response.use(response => {
    var pathname = window.location.pathname
    if (response.data.code === 900 && apis.getOpenRoute().indexOf(pathname) === -1) {
        setTimeout(() => {
            window.location.href = generateSsoUrl()
        }, 2000)
    } else {
        return response
    }
})


//通用接口列表
var apis = {
    /**
     * 开放路径
     */
    getOpenRoute: function () {
        return ['/sso']
    },
    /**
     * 内容分页获取
     * @param endpoint
     * @param pageNumber
     * @param pageSize
     * @param filter
     * @returns {Promise<AxiosResponse<T>>}
     */
    getPagination(endpoint,pageNumber = 1, pageSize = 20,filter=null) {
    var param = filter ? filter : {}
    param.pageNumber = pageNumber
    param.pageSize = pageSize
    return httpClient(endpoint,param)
},
    /**
     * 登陆用户信息
     */
    getSignUserInfor: function () {
        return httpClient("/sign/user")
    },
    /**
     * 登陆验证
     * @param verify
     * @returns {Promise<AxiosResponse<T>>}
     */
    makeVerify: function (verify) {
        return httpClient("/user/sign",verify,"open","")
    },
    /**
     * 限制数量为500
     */
    getMerchantGrantGroup: function () {
        return httpClient("/user/auth/groups")
    },
    /**
     * 修改商户信息ss
     * @param params
     */
    merchantModify: function (params) {
        delete params.id
        return httpClient("/merchant/info/update",params)
    },
    /**
     * 授权详情
     * @param shipId
     */
    getShipperDetail: function (shipId) {
        var param = {}
        param.id = shipId
        return httpClient("/merchant/shipper/detail",param)
    },
    /**
     * 国家列表
     * @returns {Promise<AxiosResponse<T>>}
     */
    getCountrys: function () {
        return httpClient("","","","","https://api.tapjek.com/op/open/country/list")
    },
    /**
     * 值转规则
     * @param formula
     */
    vlaueConvertAdmin: function (formula) {
        return httpClient("/merchant/convert/formula",formula)
    },
    /**
     * 可用授权通道
     * @param filter
     * @returns {Promise<AxiosResponse<T>>}
     */
    avaiableGrants: function (filter) {
        return httpClient("/system/grants",filter)
    },
    /**
     * 提交开发者
     * @param dev
     */
    developerModify: function (dev) {
        return httpClient("/developer/modify",dev)
    },
    /**
     * 电子面单重采
     * @param orderId
     */
    rePurchaseLabel: function (orderId) {
        return httpClient("/label/reorder",{id: orderId})
    },
    /**
     * 订单重新通知
     * @param orderId
     */
    reSendNotify: function (orderId) {
        return httpClient("/label/renotify",{id: orderId})
    },
    /**
     * 重新同步面单
     * @param {} orderId 
     */
    labelReAsync: function (orderId) {
        return httpClient("/label/resync",{id: orderId})
    },
    /**
     * 用户菜单
     * @param filter
     * @returns {Promise<AxiosResponse<any>>}
     */
    getUserMenus: function (filter) {
        return httpClient("/user/menus",filter)
    },
    /**
     * 授权字段同步
     * @param grantId
     * @returns {Promise<AxiosResponse<any>>}
     */
    grantFieldSync: function (grantId) {
        return httpClient("/system/authorization/fields", {grantId: grantId})
    },
    /**
     * 提交授权信息
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    authorizationApply: function (params) {
        return httpClient("/system/authorization/modify", params)
    },
    /**
     * 当前应用初始化 /open/client/infos
     */
    appClientSync: function () {
        var host = window.location.host
        return httpClient("/client/infos", {host: host},"open","")
    },
    /**
     * 自动转换任务
     * @param filter
     * @returns {Promise<AxiosResponse<any>>}
     */
    transformChannelSync: function (filter) {
        return httpClient("/scheduler/transform/channel/list", filter)
    },
    /**
     * 授权帐户同步
     * @param filter
     * @returns {Promise<AxiosResponse<any>>}
     */
    verifiedAuthorizationsSync: function (filter) {
        return httpClient("/system/auth/syn", filter)
    },
    /**
     * 自动化任务订阅管理
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    handleSchedulerSubscribe: function (params) {
        return httpClient("/scheduler/transform/channel/modify", params)
    },
    /**
     * 用户自动化任务订阅列表
     * @param filter
     * @returns {Promise<AxiosResponse<any>>}
     */
    syncUserSchedulers: function (filter) {
        return httpClient("/scheduler/transform/channel/subscribe", filter)
    },
    /**
     * 谷歌购物产品列表
     * @param filter
     * @returns {Promise<AxiosResponse<any>>}
     */
    googleShoppingProducts: function (filter) {
        return httpClient("/spread/google/shopping/item/list", filter)
    },
    /**
     * 文件上传
     * @param formData
     * @returns {Promise<AxiosResponse<any>>}
     */
    fileUpload: function (formData) {
        return httpClient("/util/upload", formData)
    },
    /**
     * 谷歌购物产品更新
     * @param item
     * @returns {Promise<AxiosResponse<any>>}
     */
    makeGoogleShoppingItemUpdate: function (item) {
        return httpClient("/spread/google/shopping/product/item/edit", item)
    },
    /**
     * 谷歌购物产品变体加载
     * @param parentId
     * @returns {Promise<AxiosResponse<any>>}
     */
    getGoogleShoppingItemVariants: function (parentId) {
        return httpClient("/spread/google/shopping/product/variant/list", {id: parentId})
    },
    /**
     * 产品信息编辑
     * @param variant
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateGoogleShoppingVariant:function (variant) {
        return httpClient("/spread/google/shopping/product/variant/edit", variant)
    },
    /**
     * 电商产品更新
     * @param item
     * @returns {Promise<AxiosResponse<any>>}
     */
    catalogItemUpdate: function (item) {
        return httpClient("/spread/catalog/product/item/edit", item)
    },
    /**
     * 电商产品产品变体加载
     * @param parentId
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCatalogItemVariants: function (parentId) {
        return httpClient("/spread/catalog/product/variant/list", {id: parentId})
    },
    /**
     * 产品信息编辑
     * @param variant
     * @returns {Promise<AxiosResponse<any>>}
     */
    updateCatalogVariant:function (variant) {
        return httpClient("/spread/catalog/product/variant/edit", variant)
    },
    /**
     * 电商产品产品列表
     * @param filter
     * @returns {Promise<AxiosResponse<any>>}
     */
    catalogProducts: function (filter) {
        return httpClient("/spread/catalog/item/list", filter)
    },
    /**
     * 采集引擎
     * @param filter
     * @returns {Promise<AxiosResponse<any>>}
     */
    crawlerEngine: function (filter) {
        return httpClient("/bigdata/spider/engine", filter)
    },
    /**
     * 用户订阅信息
     * @returns {Promise<AxiosResponse<any>>}
     */
    getUserCrawlerEngineSubscribeInfo: function () {
        return httpClient("/bigdata/spider/subscribe/info", {})
    },
    /**
     * 采集引擎用户订阅提交
     * @param id
     * @returns {Promise<AxiosResponse<any>>}
     */
    handUserSpiderEngineSubscribe: function (id) {
        return httpClient("/bigdata/spider/subscribe/admin", {id: id})
    },
    /**
     * 比价店铺
     * @param shop
     * @returns {Promise<AxiosResponse<any>>}
     */
    priceTrackingShopAdmin: function (shop) {
        return httpClient("/bigdata/resource/shop/admin", shop)
    },
    /**
     * 用户店铺列表
     * @param filter
     * @returns {Promise<AxiosResponse<any>>}
     */
    priceTrackingShopList: function (filter) {
        return httpClient("/bigdata/resource/shop/list", filter)
    },
    /**
     * 店铺产品列表
     * @param filter
     * @returns {Promise<AxiosResponse<any>>}
     */
    getShopItems: function (filter) {
        return httpClient("/bigdata/resource/shop/product/list", filter)
    },
    /**
     * 采集对像
     * @param filter
     * @returns {Promise<AxiosResponse<any>>}
     */
    getSpiderItems: function (filter) {
        return httpClient("/bigdata/spider/items", filter)
    },
    /**
     * 采集对像记录列表
     * @param filter
     * @returns {Promise<AxiosResponse<any>>}
     */
    getCrawlerRecords: function (filter) {
        return httpClient("/bigdata/price/record", filter)
    },
    /**
     * 样例数据
     * @param cmd
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    getSimpleData: function(cmd) {
         return httpClient("/util/simple-data", {cmd: cmd})
    },
    /**
     * 第三方公共接口调用
     * @param cmd
     * @param params
     * @returns {Promise<AxiosResponse<any>> | Promise<axios.AxiosResponse<any>> | *}
     */
    gwApi: function (cmd, params) {
         return httpClient("/util/thirdpart/gw-api", {cmd: cmd, args: params})
    }
}

var uploadUrl = ""
export default apis
export {
    httpClient,
    uploadUrl
}