import React from "react"
import * as ui from "antd";

const {Option} = ui.Select
const {TextArea} = ui.Input
export default  class AuthFieldFormItem extends React.Component{
    constructor(prop) {
        super(prop);
    }
    render() {
        var that = this
        return (
            <span>
                {that.props.fieldData.map((fields,index)=>{
                    if (fields.formType === "select") {
                        return (
                            <ui.Form.Item key={index}
                                          label={fields.name}
                                          name={`property___${fields.field}`}
                                          help={fields.help ? fields.help : ""}
                                          rules={[{required:fields.required,message:fields.placeHolder}]}>
                                            {fields.fixValue.map((vls,index)=>{
                                                return (
                                                    <Option key={index} value={vls.value}>{vls.key}</Option>
                                                )
                                            })}
                            </ui.Form.Item>
                        )
                    } else if (fields.formType === "textarea") {
                        return (
                            <ui.Form.Item key={index}
                                          label={fields.name}
                                          name={`property___${fields.field}`}
                                          help={fields.help ? fields.help : ""}
                                          rules={[{required:fields.required,message:fields.placeHolder}]}>
                                <TextArea rows={fields.rows} placeholder={fields.placeHolder}/>
                            </ui.Form.Item>
                        )
                    }else {
                        return (
                            <ui.Form.Item key={index}
                                          label={fields.name}
                                          name={`property___${fields.field}`}
                                          help={fields.help ? fields.help : ""}
                                          rules={[{required:fields.required,message:fields.placeHolder}]}>
                                <ui.Input placeholder={fields.placeHolder}/>
                            </ui.Form.Item>
                        )
                    }
                })}
            </span>
        )
    }
}