import Layout from "./pages/Layout";
import './style.css';
import 'antd/dist/antd.css'
import '@icon-park/react/styles/index.css'
import GlobalData from "./utils/GlobalData";

function App() {
  return (
    <Layout/>
  );
}

export default App;
