import React from "react"
import apis from "../../utils/NetUtils"
import * as icon from "@ant-design/icons"
import * as ui from  "antd"
import RouterView from "../../router/Router"
import qs from "qs"

export default class Sso extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        this.makeSign()
    }

    /**
     * 用户登陆
     */
    makeSign(){
        var that = this
        var query = qs.parse(that.props.location.search,{ignoreQueryPrefix: true})
        var param = {}
        param.code = query.code
        apis.makeVerify(param).then(resp => {
            if (resp.data.code === 200) {
                localStorage.setItem("appToken", resp.data.data)
                setTimeout(() => {
                    window.location.href = "/"
                }, 2000)
            } else {
                ui.message.error(resp.data.msg)
            }
        })
    }
    render() {
        return (
            <div></div>
        )
    }
}