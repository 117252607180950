import React from "react"
import apis from "../../utils/NetUtils"
import * as icon from "@ant-design/icons"
import * as ui from  "antd"
import LoadUtil from "../../utils/LoadUtil";

const {Option} = ui.Select

export default class  InitialShipperGroup extends React.Component{
    constructor() {
        super();
        this.state = {
            groups: [],
            btnLoading: false
        }
    }
    componentDidMount() {
        this.getMerchantGrantGroups()
    }

    /**
     * 获得商户授权组
     */
    getMerchantGrantGroups() {
        var  that = this
        apis.getMerchantGrantGroup().then(resp => {
            that.setState({
                groups: resp.data.data
            })
        })
    }
    render() {
        var that = this
        return (
            <div style={{width:"520px",backgroundColor:"#ffffff",padding:"22px",margin:"110px auto"}}>
                <h1>系统快递初始化配置</h1>
                <small>用户可通过洛河图志或则云平台管理授权</small>
                <ui.Divider/>
                <ui.Form onFinish={(params) => {
                    that.setState({
                        btnLoading: true
                    })
                    params.group_id = params.group_id.toString()
                    apis.merchantModify(params).then(resp => {
                        that.setState({
                            btnLoading: false
                        })
                        if (resp.data.code === 200) {
                            ui.message.success("绑定授权组成功")
                            window.location.href = '/'
                        } else {
                            ui.message.error(resp.data.msg)
                        }
                    })
                }} labelCol={{span: 24}} labelAlign={"left"}>
                    <ui.Form.Item label={"请选择您的物流分组"}
                                  rules={[{required:true,message:"请选择系统物流组"}]}
                                  name={"group_id"}>
                        <ui.Select style={{width:"100%"}} placeholder={"请选择您的物流授权组"}>
                            {that.state.groups.map(elem => {
                                return (
                                    <Option key={elem.id} value={elem.id}>{elem.name}</Option>
                                )
                            })}
                        </ui.Select>
                    </ui.Form.Item>
                    <ui.Form.Item>
                        <ui.Button loading={that.state.btnLoading} htmlType={"submit"} type={"primary"} shape={"round"} block>确认系统物流授权分组信息</ui.Button>
                    </ui.Form.Item>
                </ui.Form>
            </div>
        )
    }
}