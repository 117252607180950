import React from "react"
import apis,{httpClient} from "../../utils/NetUtils"
import * as icon from "@ant-design/icons"
import * as ui from  "antd"
import LoadUtil from "../../utils/LoadUtil";
import moment from "moment";
import AuthFieldFormItem from "../../components/plugins/AuthFieldFormItem";
import {convertObjectSubField} from "../../utils/ToolUtils";

const {Option} = ui.Select
const {TextArea} = ui.Input

export default class Shippers extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            grant: {},
            tableData: [],
            pagination: {total: 0, currPage: 1},
            keywords: {},
            tableLoading: false,
            editCurr: {},
            editShow: false,
            fields: [],
            group: [],
            handleLoading: false
        }
    }
    componentDidMount() {
        this.getGrant()
        this.GetTableData()
    }

    /**
     * 授权初始化
     * @param {*} data 
     */
    initial = (data) => {
        var current = data ? JSON.parse(JSON.stringify(data)) : {}
        if (current.property) {
            current.property = JSON.parse(current.property)
        }
        current = convertObjectSubField.encodeObject2Fields(current)
        this.setState({
            editCurr: current,
            editShow: true,
            handleLoading: false
        })
    }

    /**
     * 获得当前配送方式授权字段
     * @param {*} grantId 
     */
    getGrantFields (grantId) {
        var that = this
        apis.grantFieldSync(grantId).then(resp => {
            that.setState({
                fields: resp.data.data
            })
        })
    }

    /**
     * 获得授权帐户列表
     */
    getGrant(){
        console.log(this.props.match.params)
        var that = this
        apis.getPagination("/system/grants",1,1,{code: that.props.match.params.carrierCode, SystemGroup__code: 'logictics'}).then(resp=>{
            that.setState({
                grant: resp.data.data.data[0]
            })
            that.getGrantFields(resp.data.data.data[0].id)
        })
    }

    /**
     * 提交新的物流方式
     * @param values
     * @constructor
     */
    Handle=(values)=>{
        values = convertObjectSubField.decodeFields2Object(values)
        var that = this
        if (that.state.grant.id) {
            if (that.state.editCurr.id) {
                values.id = that.state.editCurr.id;
            }
            values.grant_id = that.state.grant.id
            that.setState({
                handleLoading: true
            })
            apis.authorizationApply(values).then(resp=> {
                that.setState({
                    handleLoading: false
                })
                if (resp.data.code === 200 && resp.data.data.code === 200) {
                    ui.message.success("更新授权信息成功")
                    that.setState({
                        editCurr: {},
                        editShow: false
                    })
                    that.GetTableData(values.id ? that.state.pagination.currPage : 1)
                } else {
                    ui.message.error(resp.data.data.msg ? resp.data.data.msg : resp.data.msg)
                }
            })

        } else  {
            ui.message.error("当前授权通道不存在")
        }
    }
    /**
     * 数据列表
     * @param pageNumber
     * @param pageSize
     * @param filter
     * @constructor
     */
    GetTableData=(pageNumber=1,pageSize=20)=>{
        var that = this
        var para = {}
        para.pageNumber = pageNumber
        para.pageSize = pageSize
        Object.assign(para, that.state.keywords)
        that.setState({
            tableLoading: true
        })
        var fileter = that.state.keywords
        fileter.grant__code = that.props.match.params.carrierCode
        apis.getPagination("/merchant/shippers",pageNumber,pageSize,fileter).then(resp=>{
            that.setState({
                tableLoading: false
            })
            console.log(resp.data.data)
            var pageData = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableLoading: false,
                pagination: pageData,
                tableData: resp.data.data.data
            })
        })

    }
    /**
     * 发起搜索
     * @param params
     */
    makeSearch = (params) => {
        this.setState({
            keywords: params
        })
        this.GetTableData()
    }


    editRef = React.createRef;
    render() {
        var that = this
        var columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: '快递名称',
                dataIndex: "name"
            },
            {
                title: "快递渠道",
                key: "id",
                render: function (value, row, index) {
                    return row.grant.name
                }
            },
            {
                title: '创建时间',
                key: 'id',
                render: function (value, row, index) {
                    return moment(row.createAt).format("yyyy-MM-DD")
                }
            },
            {
                title: '授权管理',
                key: 'id',
                fixed:"right",
                width:120,
                render: function (value, row, index) {
                    return (
                        <ui.Button.Group>
                            <ui.Button type={'link'} onClick={() => {
                                that.initial(row)
                            }}>编辑</ui.Button>
                        </ui.Button.Group>
                    )
                }
            }
        ]
        return (
            <div style={{margin:"26px"}}>
                <ui.Breadcrumb>
                    <ui.Breadcrumb.Item>
                        <a href={"/"}>首页</a>
                    </ui.Breadcrumb.Item>
                    <ui.Breadcrumb.Item>
                        <a href={"/service/label"}>电子面单</a>
                    </ui.Breadcrumb.Item>
                    <ui.Breadcrumb.Item>电子面单渠道管理</ui.Breadcrumb.Item>
                </ui.Breadcrumb>
                <ui.PageHeader style={{marginTop:"26px"}} ghost={false}
                               title={`${that.state.grant.name}授权管理`}
                               subTitle={`${that.state.grant.explain}`}
                               extra={[<ui.Button type={"primary"} shape="round" onClick={() => {
                                   that.initial(null)
                               }}>新增授权</ui.Button>]}/>
                <div id={"toolbar"} style={{marginTop:"16px"}}>
                    <ui.Form onFinish={that.makeSearch} layout={"inline"}>
                        <ui.Form.Item name={"name"}>
                            <ui.Input placeholder={'渠道名称...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item>
                            <ui.Button htmlType={"submit"} type={"primary"}>帐户搜索</ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </div>
                <div style={{marginTop:"26px"}}>
                    <ui.Card>
                        <ui.Table
                            dataSource={that.state.tableData}
                            columns={columns}
                            loading={that.state.tableLoading}
                            pagination={{
                                total: that.state.pagination.total,
                                defaultPageSize:20,
                                defaultCurrent: that.state.pagination.currPage,
                                onChange: that.GetTableData
                            }}
                            rowKey={"id"}/>
                    </ui.Card>
                </div>
                <ui.Modal title="授权管理" footer={null} open={that.state.editShow} onCancel={()=>{
                    that.setState({
                        editShow:false
                    })
                }} destroyOnClose>
                    <ui.Form onFinish={that.Handle} labelCol={{span:24}} ref={that.editRef} labelAlign={"left"} initialValues={that.state.editCurr}>
                        <ui.Form.Item label="授权名称" name="name" rules={[{required:true,message:"请输入授权名称"}]}>
                            <ui.Input placeholder="请输入授权名称..."/>
                        </ui.Form.Item>
                        <AuthFieldFormItem fieldData={that.state.fields} onChange={(field, value, event) => {
                            var currentArgs = {}
                            currentArgs[field] = value
                            that.editRef.current.setFieldValues(currentArgs)
                        }}/>
                        <ui.Form.Item style={{textAlign:'right'}}>
                            <ui.Button type="primary" shape="round" loading={that.state.handleLoading} htmlType="submit">提交授权信息</ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>
            </div>
        )
    }
}