import React from "react"
import apis,{httpClient} from "../../utils/NetUtils"
import * as icon from "@ant-design/icons"
import * as ui from  "antd"
import SmartEditor from "../../components/SmartEditor";
import {HasLoaded, StartLoading} from "../../utils/LoadUtil";
import {Textarea} from "@icon-park/react";

const  {TextArea} = ui.Input
export default  class CatalogProducts extends React.Component{
    constructor(props) {
        super();
        this.state = {
            channels: [],
            editCurr: {},
            editShow: false,
            editLoading: false,
            selectedChannel: {},
            keywords: {},
            pagination: {
                total: 0,
                currPage: 1
            },
            variants: [],
            variantViewShow: false,
            variantEditShow: false,
            variantEditCurr: {},
            variantHandleLoading: false
        }
    }

    componentDidMount() {
        this.tableDataSync(1)
    }

    /**
     * 加载产品子变体
     * @param parentId
     */
    syncItemVariants (parentId) {
        var that = this
        that.setState({
            variants: []
        })
        StartLoading()
        apis.getCatalogItemVariants(parentId).then(res => {
            HasLoaded()
            that.setState(({
                variants: res.data.data
            }))
        })
    }

    /**
     * 数据列表
     * @param pageNumber
     * @param pageSize
     * @param filter
     * @constructor
     */
    tableDataSync=(pageNumber=1,pageSize=20)=>{
        var that = this
        var para = {}
        para.pageNumber = pageNumber
        para.pageSize = pageSize
        that.setState({
            tableLoading: true
        })
        Object.assign(para,that.state.keywords)
        apis.catalogProducts(para).then(resp=>{
            that.setState({
                tableLoading: false
            })
            var pageData = {
                total: resp.data.data.total,
                currPage: pageNumber
            }
            that.setState({
                tableLoading: false,
                pagination: pageData,
                tableData: resp.data.data.data
            })
        })

    }

    editRef = React.createRef()
    render() {
        var that = this
        var columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "产品MPN",
                dataIndex: "group_sku_id"
            },
            {
                title: "可推送",
                key: "id",
                render: function (value, row, index) {
                    return <ui.Button type={"primary"} size={"small"} danger={row.sync} shape={"circle"}>{row.sync ? '是' : '否'}</ui.Button>
                }
            },
            {
                key: "id",
                width:"70px",
                align:"center",
                render: function (value, row, index) {
                    return <ui.Image src={row.image_link} style={{width:"68px",height:"68px",objectFit:"cover"}}/>
                }
            },
            {
                title: '产品标题',
                key: "id",
                render: function (value, row, index) {
                    return (
                        <div>
                            <small><h4>{row.item_category}</h4></small>
                            <a href={row.link} target={"_blank"}>
                                <h4 style={{color:"darkcyan",fontSize:"16px"}}>{row.title}<small style={{marginLeft:"8px",color:"#666"}}>({row.group_sku_id})</small></h4>
                            </a>
                            <small>{row.item_id}</small>
                        </div>
                    )
                }
            },
            {
                title: '推送渠道',
                key: "id",
                render: function (value, row, index) {
                    return (
                        <div>
                            <h4 style={{color:"hotpink"}}>{row.transform_channel.name}</h4>
                            <small>{row.transform_channel.remark}</small>
                        </div>
                    )
                }
            },
            {
                title: '推送任务指纹',
                key: "id",
                render: function (value, row, index) {
                    return row.current_sync_figure ? row.current_sync_figure : '尚未推送'
                }
            },
            {
                title: '创建时间',
                key: "id",
                render: function (value, row, index) {
                    var dd = new Date(row.create_time)
                    return <span>{dd.toLocaleDateString()}  {dd.toLocaleTimeString()}</span>
                }
            },
            {
                title: '最后更新',
                key: "id",
                render: function (value, row, index) {
                    var dd = new Date(row.last_modified)
                    return <span>{dd.toLocaleDateString()}  {dd.toLocaleTimeString()}</span>
                }
            },
            {
                title: '管理',
                key: "id",
                fixed: "right",
                width: 140,
                render: function (value, row, index) {
                    return (
                        <span>
                            <ui.Button type={"link"} size={"small"} onClick={() => {
                                var data = JSON.parse(JSON.stringify(row))
                                that.setState({
                                    editCurr: data,
                                    editShow: true,
                                    editLoading: false
                                })
                            }}>产品编辑</ui.Button>
                            <ui.Button type={"link"} size={"small"} onClick={() => {
                                console.log("变体编辑")
                                that.syncItemVariants(row.id)
                                that.setState({
                                    variantViewShow: true
                                })
                            }}>变体编辑</ui.Button>
                        </span>
                    )
                }
            }
        ]
        var cols = [
            {
                title: "skuId",
                dataIndex: "id"
            },
            {
                title: "sku",
                dataIndex: "sku"
            },
            {
                title: "价格",
                dataIndex: "price"
            },
            {
                title: "销售价格",
                dataIndex: "sale_price"
            },
            {
                title: "货币",
                key: "id",
                render: function (value, row, index) {
                    return row.currency.toUpperCase()
                }
            },
            {
                title: "产品重量",
                key: "id",
                render: function (value, row, index) {
                    return row.pkg_weight
                }
            },
            {
                title: "可售产品数量 ",
                key: "id",
                render: function (value, row, index) {
                    return row.qty
                }
            },
            {
                title: "产品长",
                key: "id",
                render: function (value, row, index) {
                    return row.pkg_length
                }
            },
            {
                title: "产品宽",
                key: "id",
                render: function (value, row, index) {
                    return row.pkg_width
                }
            },
            {
                title: "产品高",
                key: "id",
                render: function (value, row, index) {
                    return row.pkg_height
                }
            },
            {
                title: "变体管理",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <span>
                            <ui.Button type={"link"} size={"small"} onClick={() => {
                                var data = JSON.parse(JSON.stringify(row))
                                that.setState({
                                    variantEditCurr: data,
                                    variantEditShow: true,
                                    variantHandleLoading: false
                                })
                            }}>编辑</ui.Button>
                        </span>
                    )
                }
            }
        ]
        return (
            <div style={{margin:"26px"}}>
                <ui.Breadcrumb>
                    <ui.Breadcrumb.Item>
                        <a href={"/"}>首页</a>
                    </ui.Breadcrumb.Item>
                    <ui.Breadcrumb.Item>产品分发管理</ui.Breadcrumb.Item>
                </ui.Breadcrumb>
                <ui.PageHeader style={{marginTop:"26px"}} ghost={false}
                               title={`产品分发`} subTitle={"多平台产品同步"} extra={[]}/>
                <div id={"toolbar"} style={{margin:"16px"}}>
                    <ui.Form  onFinish={param => {
                        that.setState({
                            keywords: param
                        })
                        that.tableDataSync(1)
                    }} layout={"inline"}>
                        <ui.Form.Item name={"tilte__icontains"} label="产品名称">
                            <ui.Input placeholder={'产品名称...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item name={"offer_if"} label="产品SKU">
                            <ui.Input placeholder={'产品SKU...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item name={"item_group"} label="产品ID">
                            <ui.Input placeholder={'产品ID...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item name={"brand"} label="产品品牌">
                            <ui.Input placeholder={'产品品牌...'}/>
                        </ui.Form.Item>
                        <ui.Form.Item>
                            <ui.Button htmlType={"submit"} type={"primary"}>产品搜索</ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </div>
                <div style={{marginTop:"26px"}}>
                    <ui.Card>
                        <ui.Table
                            dataSource={that.state.tableData}
                            columns={columns}
                            loading={that.state.tableLoading}
                            pagination={{
                                total: that.state.pagination.total,
                                defaultPageSize:20,
                                defaultCurrent: that.state.pagination.currPage,
                                onChange: that.tableDataSync
                            }}
                            rowKey={"id"}/>
                    </ui.Card>
                </div>

                <ui.Modal mask open={that.state.variantEditShow} footer={null} onCancel={() => {
                    that.setState({
                        variantEditCurr: {},
                        variantEditShow: false,
                        variantHandleLoading: false
                    })
                }} destroyOnClose>
                    <h2>变体编辑</h2>
                    <ui.Divider/>
                    <ui.Form onFinish={params => {
                        params.id = that.state.variantEditCurr.id
                        that.setState({
                            variantHandleLoading: true
                        })
                        apis.updateCatalogVariant(params).then(res => {
                            that.setState({
                                variantHandleLoading: false
                            })
                            if (res.data.code === 200) {
                                ui.message.success("更新产品信息成功")
                                that.syncItemVariants(that.state.variantEditCurr.id)
                                that.setState({
                                    variantEditCurr: {},
                                    variantEditShow: false,
                                    variantHandleLoading: false
                                })
                            } else {
                                ui.message.error(res.data.data.msg ? res.data.data.msg : res.data.msg)
                            }
                        })
                    }} initialValues={that.state.variantEditCurr} labelAlign={"left"} labelCol={{span: 24}}>
                        <ui.Form.Item label={"产品可推送"} name={"sync"} rules={[{required:true,message:"产品可推送"}]}>
                            <ui.Radio.Group>
                                <ui.Radio value={true}>是</ui.Radio>
                                <ui.Radio value={false}>否</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品可售"} name={"state"} rules={[{required:true,message:"产品可售"}]}>
                            <ui.Radio.Group>
                                <ui.Radio value={true}>是</ui.Radio>
                                <ui.Radio value={false}>否</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品全球编码"} name={"gtin"} rules={[{required:false ,message:"产品全球编码"}]} help={"支持UPC,EIN等"}>
                            <ui.Input placeholder={"产品全球编码"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"可售产品数量"} name={"qty"} rules={[{required:true,message:"可售产品数量"}]} >
                            <ui.InputNumber style={{width:"100%"}} placeholder={"可售产品数量"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品货币"} name={"currency"} rules={[{required:true,message:"产品货币"}]} >
                            <ui.Input placeholder={"产品货币"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品价格"} name={"price"} rules={[{required:true,message:"产品价格"}]}>
                            <ui.InputNumber style={{width:"100%"}}  min={0.01} placeholder={"产品价格"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"销售价格"} name={"sale_price"} rules={[{required:true,message:"销售价格"}]}>
                            <ui.InputNumber style={{width:"100%"}} min={0.01} precision={2} placeholder={"销售价格"}/>
                        </ui.Form.Item>

                        <ui.Form.Item label={"产品重量"} name={"pkg_weight"} rules={[{required:true,message:"产品重量"}]}>
                            <ui.InputNumber style={{width:"100%"}} min={1} precision={0} placeholder={"产品重量"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品长度"} name={"pkg_length"}  rules={[{required:true,message:"产品长度"}]}>
                            <ui.InputNumber style={{width:"100%"}} min={1} parser={0} placeholder={"产品长度"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品宽度"} name={"pkg_width"}  rules={[{required:true,message:"产品宽度"}]}>
                            <ui.InputNumber style={{width:"100%"}} min={1} precision={0} placeholder={"产品宽度"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品高度"} name={"pkg_height"}  rules={[{required:true,message:"产品高度"}]}>
                            <ui.InputNumber style={{width:"100%"}} min={1} precision={0} placeholder={"产品高度"}/>
                        </ui.Form.Item>


                        <ui.Form.Item style={{textAlign:'right'}}>
                            <ui.Button type="primary" shape="round" loading={that.state.variantHandleLoading} htmlType="submit">提交产品信息</ui.Button>
                        </ui.Form.Item>
                    </ui.Form>
                </ui.Modal>

                <ui.Modal mask width={"90vw"} open={that.state.variantViewShow} footer={null} onCancel={() => {
                    that.setState({
                        variantViewShow: false
                    })
                }} destroyOnClose>
                    <h2>产品变体信息</h2>
                    <ui.Divider/>
                    <div style={{minHeight:"60vh"}}>
                        <ui.Table
                            dataSource={that.state.variants}
                            columns={cols}
                            pagination={false}
                            rowKey={"id"}/>
                    </div>
                </ui.Modal>

                <ui.Modal width={"760px"}  open={that.state.editShow} footer={null} onCancel={() => {
                    that.setState({
                        editCurr: {},
                        editShow: false,
                        editLoading: false
                    })
                }} destroyOnClose>
                    <h2>购物产品编辑</h2>
                    <small>请按照相应平台参数说明进行填写</small>
                    <ui.Divider/>
                    <ui.Form  ref={that.editRef} onFinish={params => {
                        params.id = that.state.editCurr.id
                        that.setState({
                            editLoading: true
                        })
                        apis.catalogItemUpdate(params).then(res => {
                            that.setState({
                                editLoading: false
                            })
                            if (res.data.code === 200) {
                                ui.message.success("更新购物产品信息成功")
                                that.setState({
                                    editCurr: {},
                                    editShow: false,
                                    editLoading: false
                                })
                                that.tableDataSync(that.state.editCurr.id ? that.state.pagination.currPage : 1)
                            } else {
                                ui.message.error(res.data.data.msg ? res.data.data.msg : res.data.msg)
                            }
                        })
                    }} initialValues={that.state.editCurr} labelAlign={"left"} labelCol={{span: 24}}>
                        <ui.Form.Item label={"产品标题"} name={"title"} rules={[{required:true,message:"产品标题"}]}>
                            <ui.Input placeholder={"产品标题"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品亮点"} name={"highlights"} rules={[{required:false,message:"产品亮点"}]} help={"建议五点亮点,换行一个"}>
                            <TextArea rows={10} placeholder={"产品亮点"}/>
                        </ui.Form.Item>

                        <ui.Form.Item label={"产品可推送"} name={"sync"} rules={[{required:true,message:"产品可推送"}]}>
                            <ui.Radio.Group>
                                <ui.Radio value={true}>是</ui.Radio>
                                <ui.Radio value={false}>否</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品可售"} name={"state"} rules={[{required:true,message:"产品可售"}]}>
                            <ui.Radio.Group>
                                <ui.Radio value={true}>是</ui.Radio>
                                <ui.Radio value={false}>否</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品编码"} name={"gtin"} rules={[{required:false,message:"产品编码"}]} help={"支持upc,ean等，图书请使用图片全球编码"}>
                            <ui.Input placeholder={"产品编码"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"长度单位"} name={"length_unit"} rules={[{required:true,message:"长度单位"}]}>
                            <ui.Input placeholder={"长度单位"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"平台产品分类"} name={"target_category"}
                                      rules={[{required:true,message:"平台产品分类"}]} help={"如facebook，请查看相应文档，按文档规则填写"}>
                            <ui.Input placeholder={"平台产品分类"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品情况"} name={"condition"} rules={[{required:true,message:"产品类型"}]}>
                            <ui.Radio.Group>
                                <ui.Radio value={"new"}>New</ui.Radio>
                                <ui.Radio value={"refurbished"}>Refurbished</ui.Radio>
                                <ui.Radio value={"used"}>Used</ui.Radio>
                            </ui.Radio.Group>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品重量"} name={"pkg_weight"} rules={[{required:false,message:"产品重量"}]}>
                            <ui.InputNumber precision={2} style={{width: "100%"}} placeholder={"产品重量"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品长度"} name={"pkg_length"} rules={[{required:false,message:"产品长度"}]}>
                            <ui.InputNumber precision={2} style={{width: "100%"}} placeholder={"产品长度"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品宽度"} name={"pkg_width"} rules={[{required:false,message:"产品宽度"}]}>
                            <ui.InputNumber precision={2} style={{width: "100%"}} placeholder={"产品宽度"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品高度"} style={{width: "100%"}} name={"pkg_height"} rules={[{required:false,message:"产品高度"}]}>
                            <ui.InputNumber precision={2} style={{width: "100%"}} placeholder={"产品高度"}/>
                        </ui.Form.Item>
                        <ui.Form.Item label={"产品描述"} name={"description"} onChange={html=>{
                            that.editRef.current.setFieldsValue({
                                description: html
                            })
                        }} rules={[{required:true,message:"产品描述"}]}>
                            {/*<ui.Input.TextArea rows={6} placeholder={"产品描述"}/>*/}
                            <SmartEditor />
                        </ui.Form.Item>
                        <ui.Form.Item style={{textAlign:'right'}}>
                            <ui.Button type="primary" shape="round" loading={that.state.editLoading} htmlType="submit">提交产品信息</ui.Button>
                        </ui.Form.Item>

                    </ui.Form>
                </ui.Modal>
            </div>
        )
    }
}