/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2023/12/12
 */


const  OPEN_API_BASE = "https://api.hottol.com/op/"

let _httpPost = function (endpoint, data = undefined) {
    var api = `${OPEN_API_BASE}${endpoint}`
    return fetch(api,{
        method: "POST",
        body: data ? JSON.stringify(data) : {},
        mode: 'cors',
        headers: {
            "Content-Type": "application/json"
        }
    }).then(res => {
        return res.json()
    })
}

var GWAPI = {
    /**
     * 国家列表
     * @returns {Promise<any>}
     */
    countries: function () {
        return _httpPost("open/country/list")
    },
    /**
     * 语言列表
     * @returns {Promise<any>}
     */
    languages: function () {
        return _httpPost("open/language/list")
    },
    /**
     * 货币列表
     * @returns {Promise<any>}
     */
    currencies: function () {
        return _httpPost("open/currency/list")
    }
}

export default GWAPI