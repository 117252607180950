/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2023/12/8
 */
import React, {Component} from "react";
import * as ui from "antd";
import apis from "../../../utils/NetUtils";
import {BellOutlined} from "@ant-design/icons";
import dayjs, {Dayjs} from "dayjs";


const {TextArea} = ui.Input
const {Option} = ui.Select

export default class TrackingObjects extends Component {
    constructor() {
        super();
        this.state = {
            tableData: [],
            paging: {total: 0, pageNumber: 1, pageSize: 20},
            tableLoad: false,
            searchKey: {},
            editShow: false,
            editCurr: {},
            submitting: false,
            dataPoints: []
        }
    }

    componentDidMount() {
        var that = this
        setTimeout(() => {
            that.tableDataSync(1)
        }, 500)
    }


    /**
     * 查询跟踪点
     * @param originId
     */
    dataPointSync (originId) {
        var now = new dayjs()
        var that = this
        var param = {}
        param.url_id = originId
        console.log(now.subtract(3, "month").format(""))
        param.created_time__gte = now.subtract(3, "month").format()
        apis.getCrawlerRecords(param).then(res => {
            console.log(res)
        })
    }

    /**
     * 发起搜索
     * @param params
     * @constructor
     */
    makeSearch = (params) => {
        this.setState({
            searchKey: params
        })
        var that = this
        setTimeout(function () {
            that.tableDataSync()
        }, 200)
    }
    /**
     * 签名列表
     * @param pageNumber
     Object.assign(para, that.state.keywords)
     * @param pageSize
     * @constructor
     */
    tableDataSync = (pageNumber = 1) => {
        let that = this;
        let para = {};
        para.pageNumber = pageNumber;
        para.pageSize = 20;
        that.setState({
            tableLoad: true
        })
        Object.assign(para, that.state.searchKey)
        apis.getSpiderItems(para).then(function (resp) {
            that.setState({
                tableLoad: false
            })
            var curr = {
                total: resp.data.data.total,
                pageNumber: pageNumber
            }
            that.setState({
                tableData: resp.data.data.data,
                paging: curr
            })
        })
    }


    render() {
        var that = this

        const columns = [
            {
                title: "ID",
                dataIndex: "id"
            },
            {
                title: "采集器",
                key: "id",
                render: function (value, row, index) {
                    return <span style={{fontWeight:"600"}}>{row.engine.name}</span>
                }
            },
            {
                title: "目标名称",
                key: "id",
                render: function (value, row, index) {
                    return <span style={{fontWeight:"600"}}>{row.name}</span>
                }
            },
            {
                title: "可用",
                key: "id",
                render: function (value, row, index) {
                    return (
                        <ui.Button shape={"round"} type={"circle"} style={{color: "#ffff", backgroundColor: row.state ? "darkcyan": "red"}}>{row.state ? '是' : '否'}</ui.Button>
                    )
                }
            },
            {
                title: "同步时间",
                key: "id",
                render: function (value, row, index) {
                    var dd = new Date(row.created_at)
                    return `${dd.toLocaleDateString()} ${dd.toLocaleTimeString()}`
                }
            },
            {
                title: "管理",
                key: "id",
                width:"120px",
                align: "center",
                fixed: "right",
                render: function (value, row, index) {
                    return (
                        <span>
                            <ui.Button type={"link"} href={row.url} target={"_blank"}>立即查看</ui.Button>
                            <ui.Button type={"link"} onClick={() => {
                                that.dataPointSync(row.id)
                            }}>价格跟踪</ui.Button>
                        </span>
                    )
                }
            }
        ]

        return (
            <div>
                <ui.PageHeader title="比价对像列表" subTitle="价格跟踪目标列表"/>
                <ui.Card>
                    <div style={{marginTop: '22px'}}>
                        <ui.Form onFinish={that.makeSearch} layout={"inline"}>
                            <ui.Form.Item label={"ID"} name={"id"}>
                                <ui.Input placeholder={"ID"}/>
                            </ui.Form.Item>
                            <ui.Form.Item label={"标题"} name={"title__contains"}>
                                <ui.Input placeholder={"标题"}/>
                            </ui.Form.Item>
                            <ui.Form.Item label={"引擎名称"} name={"engine__name__contains"}>
                                <ui.Input placeholder={"引擎名称"}/>
                            </ui.Form.Item>
                            <ui.Form.Item>
                                <ui.Button type={"primary"} htmlType={"submit"}>快速搜索</ui.Button>
                            </ui.Form.Item>
                        </ui.Form>
                    </div>
                </ui.Card>

                <div style={{marginTop: '22px'}}>
                    <ui.Table
                        rowKey="id"
                        dataSource={this.state.tableData}
                        columns={columns}
                        loading={this.state.tableLoad}
                        pagination={{
                            total: this.state.paging.total,
                            defaultPageSize: 20,
                            defaultCurrent: this.state.paging.pageNumber,
                            onChange: this.tableDataSync
                        }}/>
                </div>

            </div>
        )
    }
}